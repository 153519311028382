.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.chat-wrap{
  width: 425px;
  height: calc(100vh - 200px);
  position: fixed;
  bottom: 74px;
  right: 30px;
  background: #fff;
  z-index: 999;
  overflow-x: hidden;
  box-shadow: 1px 3px 20px rgba(0,0,0,.2);
}
@media only screen and (max-width:767px) {
  .chat-wrap {
    width: 340px;
    height: calc(100vh - 150px);
  }
}
@media only screen and (max-width:395px) {
  .chat-wrap {
    width: 325px;
    height: calc(100vh - 150px);
  }
}
@media only screen and (max-width:360px) {
  .chat-wrap {
    width: 270px;
    height: calc(100vh - 150px);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
