body{
    font-family: 'Lato', sans-serif;
    color:#0A1C2A;
}
.btn{
    border-radius: 0;
    padding:15px;
    font-size: 16px;
    line-height: 32px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12); 
    &:hover{
        box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    }
}
.btn-container--space{
    .btn{
        padding:5px 15px;
    }
}
.link-btn{
    font-size: 16px;
    line-height: 18px;
    color: #005FF8;
    display: inline-block;
}
.btn-primary{
    background: #8d1c1d;  
    border-color:#8d1c1d;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover{
        background-color: #7b191a;
        border-color: #7b191a;
    }
    &:active {
        background: #8d1c1d!important;
        border-color: #8d1c1d!important;
        color: #fff !important;
    }
    &:disabled{
        background: #8d1c1d!important;
        border-color: #8d1c1d!important;
    }
}
.btn-loader{
    .spinner-border{
        display: inline-block !important;
        margin-left:2px;
    }
}
.form__resend-text{
    text-align: right;
    margin-bottom: 25px;
}
/**** checkbox  */
.checkbox-wrap {
    padding-left: 19px;
    position:relative;
  }
  .checkbox-wrap label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 10px;
    color:#333;
    font-size: 18px;
    margin: 0;
    cursor: pointer;
    .link-btn{
        display: inline;
        font-size: 18px;
    }
  }
  .checkbox-wrap label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 21px;
    height: 21px;
    left: 0;
    margin-left: -20px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    background-color: #fff;
    top: 4px;
  }
  .checkbox-wrap label::after {
    display: inline-block;
    position: absolute;
    width: 21px;
    height: 21px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #999;
    border-radius: 3px;
    top: 4px;
  }
  .checkbox-wrap input[type="checkbox"],
  .checkbox-wrap input[type="radio"] {
    opacity: 0;
    z-index: 1;
    position:absolute;
  }
  .checkbox-wrap.checkbox-circle label::before {
    border-radius: 50%;
  }
  .checkbox-wrap.checkbox-inline {
    margin-top: 0;
  }
  .checkbox-wrap input[type=checkbox]:checked + label:after {
   
    content: "\2713";
  }
  .checkbox-wrap label:after {
    padding-left: 5px;
    padding-top: 1px;
    font-size: 13px;
    color: #FFf;
    background: #8d1c1d;
  }
  
/************/
html,body.bgWhite,body.bgWhite #root{
    display: table;
    height: 100%;
    width: 100%;
}
body{
    
   &.bg-im{
    width: 100%;
    height: 100%;
    background-color: #8d1c1d;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/images/login-bg.png");
   }
    &.bdy-logged-in{
        background-color: #f7f7f7;
        background-image:none;
    }
    &.bg-grey{
        background-color: #f7f7f7;
        background-image:none;
    }
}
.login-popup-wrap{
    padding: 15px 20px;
    h1{
        margin-bottom: 20px;       
    }
    ul{
        padding: 0 0 0 25px;
        li{
            list-style-type: decimal;           
        }
    }
}
.loginboard__wrapper {
    position: fixed;
    left: 0;
    min-height: auto;
    width: 63.66666667%;
    .wrapper__imagebox {
        height: 100vh;
        position: relative;    
        .imagebox__bg {
            position: absolute;
            top: 0;
            left: 0;
           
        }
    }
    .imagebox__content{
        position: relative;
        z-index:1;
        color:#fff;
        margin: 0 auto;
        padding: 0 10%;
        h1{
            font-size: 90px;
            line-height: 108px;
            font-weight: 700;
            margin-bottom: 50px;
            color: #fff;
        }
        p{
            font-size: 30px;
            line-height: 40px;
        }
    }
}
.full-vh-height {
    height: calc(100vh - 60px);
}
.flexdir-column{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
   
}
.menu{
    position: absolute;
    right:35px;
    top:25px;
    ul{
        li{
            list-style: none;
            display: inline-block;
            margin-left: 25px;
            a{
                font-size: 20px;
                color:#008C82;
                line-height: 30px;
                transition: all 0.2s;
                &:hover{
                    text-decoration: none;
                    opacity: .7;
                }
            }
        }
    }
}

.loginborad__form {
    width: 580px;
    margin:0 auto;
    position: relative;
   flex-direction: column;
   justify-content: center;
    .form__wrap {
        padding:40px 60px;
        width:100%;
        background:#fff;
        border-radius:5px;
        box-shadow: 0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 6px 8px rgba(0,0,0,0.11), 0 8px 16px rgba(0,0,0,0.11);
        .form__wrap-img{
            text-align: center;
            img{
                margin-bottom: 35px;
            }
        }
    }
}

.heading{
    font-family: 'Quicksand', sans-serif;
    color: #243746;
    font-size: 28px;
    font-weight: 500;
    line-height: 35px;
}
.link-block{
    color:#3A5060;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 30px;
    a{
        color:#005FF8;
        font-size: 16px;
    }
}
.field {
    display: flex;
    flex-flow: column-reverse;
    border-bottom: 1px solid #ccc;
    background: #fafafa;
    min-height: 55px;
    max-height: 55px;
    margin-bottom: 30px;
    position: relative;
    &.field--textarea{
        max-height:120px;
    }
    input{
        font-size: 16px;
        border: 0;        
        font-family: inherit;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 0;
        cursor: text;       
        transition: all 0.2s;
        touch-action: manipulation;    
        background: transparent;
        border:none; 
        min-height: 35px;  
        padding: 0 10px;
        box-shadow: none;
         &:focus{
            outline: 0;
            border-bottom: none;
            box-shadow: none;
            background: transparent;
          }
    }
    // input:not(:placeholder-shown),textarea:not(:placeholder-shown){
    //     border-bottom:1px solid #02cabb;
    // }
    label{
        position: absolute;
        top: 0;
        left: 10px;
        color: #333333;
        font-size: 18px;
        cursor: text;
        -webkit-transition: color .2s ease-out, -webkit-transform .2s ease-out;
        transition: color .2s ease-out, -webkit-transform .2s ease-out;
        transition: transform .2s ease-out, color .2s ease-out;
        transition: transform .2s ease-out, color .2s ease-out, -webkit-transform .2s ease-out;
        -webkit-transform: translateY(14px);
        transform: translateY(14px);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        &.label-active {
            -webkit-transform: translateY(-14px) scale(0.8);
            transform: translateY(-14px) scale(0.8);
            font-size: 15px;
            top: 15px;
        }
    }   
    // input:placeholder-shown + label,textarea:placeholder-shown + label {
    //     cursor: text;
    //     max-width: 66.66%;
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     transform-origin: left bottom;
    //     transform: translate(0, 2.125rem) scale(1.5);
    // }
    input:focus::-webkit-input-placeholder {
        opacity: 1;
    }
    input:focus::-moz-input-placeholder {
        opacity: 1;
    }
    input:focus::-ms-input-placeholder {
        opacity: 1;
    }  
    input:focus::placeholder {
        opacity:1;
    }
    ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
    }
    ::placeholder {
        opacity: 0;
        transition: inherit;
    }   
    ::-ms-input-placeholder {
        opacity: 0;
    }
    // input:not(:placeholder-shown) + label,
    // textarea:not(:placeholder-shown) + label,
    // input:focus + label,textarea:focus + label {
    //     transform: translate(0, 0) scale(1);
    //     cursor: pointer;
    //     font-size: 12px;
    //     top: 0px;
    //     color: #008C82;
    // }
           
    &.field--dob-label {
        label{
            top:2px;
        }
    }
}
.l-footer{   
    display: flex;
    justify-content: space-between;
    background: transparent;
    padding: 0;
    width: 580px;
    margin:15px auto; 
   ul{
        padding: 0;
        list-style: none;
        margin: 0;
       
       li{
            display: inline-block;
            margin-left: 20px;
            line-height: 18px;
            font-size: 15px;
            &:first-child{
                margin-left: 0;
            }
           a{
            color: #fff;
            border-bottom: 1px solid #fff;
            padding-bottom: 2px;
            &:hover{
                border-bottom: 1px solid transparent;
                text-decoration: none;
                color:#a7a7a7;
            }
           }
       }
   }  
    p{
        // color:#687986;
        color:#fff;
        display:block;
        margin-bottom: 0;
        text-align: center;
        font-size: 15px;
    }
}
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    border-color: #DD2C00;
    box-shadow: none;
}
.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
    border-color: transparent;
    box-shadow: none;
}
.form-control.is-invalid~.invalid-feedback, .form-control.is-invalid~.invalid-tooltip, .was-validated .form-control:invalid~.invalid-feedback, .was-validated .form-control:invalid~.invalid-tooltip {
    display: block;
    align-self: flex-end;
    position: absolute;
    top: 52px;
    font-size: 11px;
}
.form-control.is-invalid~.form-label,.was-validated .form-control:invalid~.form-label{
   color:#DD2C00;;
}
.form-control.is-invalid, .was-validated .form-control:invalid,.form-control.is-valid, .was-validated .form-control:valid{
    background-image: none;
    box-shadow:none ;
}
.btn-container{
    display: flex;
    justify-content: flex-end;
    .btn-primary{
        min-width: 125px;
    }
    .btn-cancel{
        border-radius:0;
        line-height: 40px;
    }
}

.error{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

body input:-webkit-autofill,
body input:-webkit-autofill:hover, 
body input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus  {
    background-color: #fff !important;
    
}
.loginboard__links{
    justify-content: flex-start;
    .link-btn{
        &:first-child{
            &:before{
                padding:0;
                content:"";
            }
        }
        &:before{
            content: "|";
            color: #545454;
            position: relative;
            padding: 0 10px;
            top: -1px;
        }
    }
}

    
@media only screen and(max-width:1400px){
    .loginboard__wrapper {
        width: 60%;
        .imagebox__content{
            h1 {
                font-size: 60px;
                line-height: 80px;
                margin-bottom: 30px;
            }
        } 
    }   
    .loginborad__form {
        .form__wrap  {
            padding:25px 40px;
            .form__wrap-img {
                img{
                    margin-bottom:20px;
                }
            }
            .heading{
                &.mb-5{
                    margin-bottom: 1.5rem !important;
                }
            }
            .link-btn {
                &.mt-4{
                    margin-top: 1rem !important;
                }
            }
            .mb-4{
                margin-bottom: 1rem !important;
            }
        }        
    } 
}
@media only screen and(max-width:1100px){
    .loginboard__wrapper {
        width: 50%;
        .imagebox__content{
            h1 {
                font-size: 45px;
                line-height: 60px;
                margin-bottom: 30px;
            }
            p {
                font-size: 25px;
                line-height: 35px;
            }
        } 
    }
    
}

@media only screen and(max-width:850px){
    .loginboard__wrapper {
        width: 45%;
        .imagebox__content{
            h1 {
                font-size: 45px;
                line-height: 60px;
                margin-bottom: 30px;
            }
            p {
                font-size: 25px;
                line-height: 35px;
            }
        } 
    }    
}
@media only screen and(max-width:767px){
    .loginboard__wrapper {
        width: 0;
        display: none !important;
        .imagebox__content{
            h1 {
                font-size: 45px;
                line-height: 60px;
                margin-bottom: 30px;
            }
            p {
                font-size: 25px;
                line-height: 35px;
            }
        } 
        
    }
    
    .loginborad__form {
        width: 100%;
        margin-left: 0;   
        background: #fff;
        .form__wrap {
            padding-top: 75px;
            padding-bottom: 50px;
            width: 100%;
            box-shadow: none;
            &.align-self-center{
                align-self: flex-start !important;
            }
            .form__wrap-img{
                img{
                    margin-bottom: 30px;
                }
            } 
        }     
    }
    .heading {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 2rem !important;
    }
    .menu {
        ul{
            li{
                a{
                    font-size: 20px;
                }
            }
        }
    }
    .l-footer {         
        text-align: center;  
        justify-content: center;
        flex-direction: column;
        width: 100%;
        p{
           color:#687986; 
           order:2;
           margin-bottom: 15px;
        }  
        ul{
            order:1;
            margin: 0 0 60px;
            li{
                a{
                    color:#687986; 
                }
            }
        }    
    }
    .full-vh-height {
        height: 100%;
    }
    body{
        background: #fff;
        &.bg-im{
            background-image: none;
            background-color: #fff;
        }
        &.bg-grey{
            background-color: #f7f7f7;
        }
        &.bdy-logged-in{
            background-color: #f7f7f7;
        }
    }

}
@media only screen and(max-width:500px){
    .loginborad__form {       
        .form__wrap {
            padding:40px 8% 35px;
            width: 100%;
        }
    }
    .menu {
        right: 8%;
        top: 20px;
    }
    .loginboard .btn-cancel{
        min-width:100px;
    }
    .l-footer {     
        ul{
            margin: 0 0 30px;
        }
    }
    .loginboard__links{
        .link-btn {
            font-size: 15px;
            line-height: 18px;
        }
    }
}
@media only screen and(max-width:430px){
    .Identifiable-title{
     padding-right:35px;
    } 
 }
@media only screen and(max-width:350px){
    .loginborad__form {       
        .form__wrap {
            padding: 40px 8% 35px;
            width: 100%;
        }
    }    
}
// @media 
// (-webkit-min-device-pixel-ratio: 2),
// (-webkit-min-device-pixel-ratio: 3), 
// (min-resolution: 192dpi) { 
//     .full-vh-height {
//         height: 100%;
//     }
// }
  
