.content-space{
    margin-top:40px;
    margin-bottom: 40px;
}
.block-space {
    padding: 30px 20%;
    &.block-space--pb-0{
        padding-bottom: 0;
    }
    &.block-space--last-child{
        .field{
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
body{
    &.backgroundWhite{
        background: #fff;
    }
}
.widgetfoter-buttons{
    background: #ededed;
    min-height: 90px;
    padding: 20px 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.invite-progress{
    h3{
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
        color: #0A1C2A;
        font-size: 22px;
        margin-bottom: 20px;
        font-weight:700;
    }
    .multi-step{
        .progtrckr{
                padding:0 21% 60px 17%;
            li {
                text-align: center;
                cursor: pointer;
                max-width: 33.333367%;
                flex: 0 0 33.333367%;
                position: relative;
                max-height: 100px;
                min-height: 89px;
                top: -20px;
            }
        }
        .a-card {
            .a-card__content{
                font-size: 16px;
            }
        }
    }
    .footer-buttons{
        background: #ededed;
        min-height: 90px;
        padding: 20px 30px;
        width: 100%;
        .btn{
            background: #8d1c1d;
            border-color: #8d1c1d;
            padding: 5px 15px;
            border-radius: 3px;
            min-height: 45px;
            &.btn-prev{
                background: #8d1c1d url(../../assets/images/next.svg) no-repeat;
                padding-left: 41px;
                background-position: 9px 11px;
                float: right;
                display: block !important;
                &:hover{
                    background: #7b191a url(../../assets/images/next.svg) no-repeat;
                    border-color: #7b191a;
                    background-position: 9px 11px;
                }
            }
            &.btn-next{
                float: left;
                background: transparent;
                border: 1px solid #BABABA;
                box-shadow: none;
                color: #909090;
                border-radius: 3px;
                padding: 5px 15px;
                min-height: 45px;
                min-width: 125px;
                line-height: 19px;
                &:hover{
                    background: #d5d5d5 !important;
                    color: #656565 !important;
                    border-color: #d5d5d5 !important;
                }
                &:disabled{
                    background: #d5d5d5 !important;
                    color: #656565 !important;
                    border-color: #d5d5d5 !important;
                }
            }
        }
    }
    .disp-inline{
        label{
            min-width:140px;
        }
        span{
            color: #0A1C2A;
            display: inline-block;
            width: calc(100% - 150px);
            vertical-align: top;
            word-break: break-word;
        }
    }
}
.btn-cancel.btn-primary:not(:disabled):not(.disabled):active{
    color: #656565 !important;
}
.in-header{
    background: #f8f8f8;
    display: flex;
    display: -ms-flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
}
.vh-content{
    min-height: calc(100vh - 300px);
}
.in-card-center{
    align-items: flex-start;
    justify-content: center;
    display: flex;
}
.in-card-subheading{
    font-size: 23px;
    line-height: 30px;
    color: #0A1C2A;
    margin-top: 2.6em;
}
.in-card {
    box-shadow: 0 1px 4px #C9CDD8;
    padding: 20px 20px 30px;
    border-radius: 5px;
    text-align: center;
    margin-top:70px;
    max-width:438px;
    background: #fff;
    .in-card__header{
        .in-card__header__subheading {
            font-size: 18px;
            color: #5E5E5E;
            line-height: 23px;
            margin-bottom: 8px;
        }
        .in-card__header__heading {
            font-size: 28px;
            line-height: 35px;
            color: #243746;
            font-family:'Quicksand';
            font-weight: 700;
            margin-bottom: 20px;
        }
    }
    .in-card__content{
        p{
            font-size: 16px;
            line-height: 22px;
            color:rgba(0,0,0,.8);
            margin-bottom: 45px;
        }
    }
    .in-card__btn{
        width:100%;
        background: #8d1c1d;
        font-size: 18px;
        line-height: 22px;
        font-family:'Lato';
        font-weight: 400;
        min-height: 55px;
        border-color:#8d1c1d;
        color:#fff;
        &:hover{
            background: #7b191a;
            border-color: #7b191a;
        }
        &.in-card__btn--blue{
            background: #8d1c1d;
            border-color:#8d1c1d;
            &:hover{
                background-color: #7b191a;
                border-color: #7b191a;
            }
        }
    }
    .invalid-feedback{
        text-align: left;
    }
}
.copyright{
    text-align: center;
    color: #687986;
    font-size: 14px;
    margin:0;
    padding-top: 140px;
    padding-bottom: 20px;
}
.a-card--readonly{
    &.field {
        background-color: #e9ecef;
        cursor: not-allowed;
    }
    .a-card__content{
        color: #909dab;
    }
}
.steps-mb-heading{
    display: none;
}
.success-msg__heading{
    background: #fafafa;
    border-bottom: 1px solid #e6e6e6;
    font-size: 20px;
    padding: 10px;
    margin-bottom: 20px;
    color: #0a1c2a;
    font-weight:600;
}
.elements-outer{
    border:1px dashed #a5a5a5;
    padding:15px 15px 20px;
    border-radius:5px;
    margin-bottom:25px;
    min-height: 220px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    .elements-outer__close{
        position: absolute;
        right: 7px;
        top: 2px;
    }
    h1{
        font-size: 23px;
    }
    h3{
        font-size: 19px;
        margin:8px 0 20px;
    }
    .elements-outer__footer {
        border-top: 1px solid #ccc;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 0 5px;
        margin-top: 20px;
    }
}
.widget {
    .table{
        &.table--elements{
            tr{
                td {
                    padding:10px;
                    max-width:145px;
                    &:first-child{
                        width:45px;
                    }
                    &:last-child{
                        max-width:145px;
                        min-width:100px;
                        width:auto;
                    }
                    .media{
                        min-width:100px;
                    }
                    h5{
                        min-width: 50px;
                    }
                }
            }
        }
    }
}
.kid-detail{
    display: flex;
    border-bottom: 1px solid #ccc;
    padding-bottom:20px;
    .kid-detail__im{
        max-width: 100px;
        max-height: 100px;
        min-height: 100px;
        min-width: 100px;
        border: 1px solid #cecece;
        border-radius: 5px;
        text-align: center;
        overflow: hidden;
        margin-right: 15px;
        img{
            max-width:100%;
        }
    }
    p{
        margin-bottom: 0;
    }
}
.loader-center{
    min-height: 250px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.loader-msg{
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
}
.consent-desc{
    margin-top:20px;
    p{
        color: #31394d;
    }
}
.invite-progress{
    &.invite-progress--student{
        .multi-step{
            .progtrckr{
                padding: 0 20% 60px 29%;
                li{
                    max-width: 68%;
                    flex: 0 0 68%;
                    &:last-child{
                        border-bottom: 4px solid #fff;
                    }
                }
            }
        }
        .btn-ternary{
            float: right;
        }
    }
}
.field-month-date {
    display: flex;
    .field--month {
        width: 50%;
        margin-right: 20px;
    }
    .field--date {
        width: 47%;
    }
    .select-wrap {
        .form-control {
            &.is-invalid {
                color: #DD2C00;
            }
        }
    }
}
.copy-link{
    .copy-link__inner {
        font-size: 16px;
        color: #005FF8;
        line-height: 20px;
        cursor: pointer;
        padding-left: 35px;
        img{
            vertical-align: middle;
        }
    }
    .copy-link__sm-value{
        font-size: 14px;
        color:#8d1c1d;
        margin-left: 10px;
    }
}
.d-note{
    margin-bottom: 15px;
    span{
        display: inline-block;
        margin: 0 0 0 7px;
    }
}

@media only screen and(max-width:1199px){
    .block-space {
        padding: 30px 12%;
    }
}
@media only screen and(max-width:1024px){
    .content-space{
        margin-top:80px;
    }
}
@media only screen and(max-width:1024px){
    .in-card-subheading{
         padding-top: 2.6em;
    }
}
@media only screen and(max-width:991px){
    .content-space{
        margin-top:80px;
    }
    .invite-header{
        .org-logo{
            display: block;
        }
    }
}

@media only screen and(max-width:767px){
    .block-space {
        padding: 30px 15%;
    }
    .in-card{
        margin-top:40px;
    }
    .in-card-center{
        &.col-sm-6{
            max-width:100%;
            flex:0 0 100%;
        }
    }
    .widget {
        .table{
            &.table--elements{
                tr{
                    td{
                        border-bottom: none;
                        display: flex;
                        padding: .7rem 1rem .7rem 118px;
                        flex-wrap: wrap;
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .field-month-date {
        flex-wrap: wrap;
        .field--month {
            width: 100%;
            margin-right: 0;
        }
        .field--date {
            width: 100%;
        }
    }
}
@media only screen and(max-width:650px){
    .invite-progress {
        .multi-step {
            .progtrckr{
                padding: 0 23% 45px 15%;
                li{
                    &:nth-child(2) {
                        em {
                            display: none;
                        }
                    }
                    em{
                        color: rgba(0,0,0,0);
                    }
                }
                .progtrckr-doing {
                    span {
                        display: block;
                        text-align: center;
                        left: -35%;
                        padding:0;
                    }
                }
            }

        }
    }
    .steps-mb-heading{
        display: block;
        padding: 30px 15% 0;
        h3{
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
}
@media only screen and(max-width:450px){
    .block-space {
        padding: 30px 25px;
    }
    .steps-mb-heading{
        padding: 30px 25px 0;
    }
    .invite-progress {
        &.invite-progres--student{
            .multi-step {
                .progtrckr {
                    .progtrckr-doing {
                        span {
                            display: block;
                            text-align: center;
                            left: -32%;
                            padding: 0;
                        }
                    }
                }
            }
        }
        .disp-inline {
            span {
                width: 100%;
            }
        }
        .multi-step {
            .progtrckr{
                padding: 0 23% 45px 11%;
                .progtrckr-doing {
                    span {
                        display: block;
                        text-align: center;
                        left: -28%;
                        padding: 0;
                    }
                }
                li{
                    &:nth-child(2) {
                        em {
                            display: none;
                        }
                    }
                }
            }
         }
    }
    .consent-desc p{
        word-break: break-word;
    }

}
@media only screen and(max-width:400px){
    .invite-progress {
        .multi-step {
            .progtrckr{
                padding: 0 23% 45px 9%;
            }
        }
        .footer-buttons{
            padding:20px 15px;
        }
    }
}

.field .invalid-label{
    color: #DD2C00;
}
.field .invalid-label.label-active{
    transform: translateY(-14px) scale(0.8);
    font-size: 12px;
    top: 17px;
    color: #DD2C00;
}
.invalid-msg{
    display: block;
    align-self: flex-end;
    position: absolute;
    top: 52px;
    font-size: 11px;
}



.radio {
    margin: 0;
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: '';
          background: #f4f4f4;
          border-radius: 100%;
          border: 1px solid darken(#f4f4f4, 25%);
          display: inline-block;
          width: 1.4em;
          height: 1.4em;
          position: relative;
          top: 2px;
          margin-right: 1em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: #005ff8;
            box-shadow: inset 0 0 0 4px #f4f4f4;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: #3197EE;
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px #f4f4f4;
            border-color: darken(#f4f4f4, 25%);
            background: darken(#f4f4f4, 25%);
          }
        }
      }
      + .radio-label {
          margin:0;
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
