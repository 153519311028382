.sidebar-wrap{
  background: #8d1c1d;
  position: relative;
  min-height: calc(100vh - 56px);
  width:224px;
  padding:0 15px;
  box-shadow: 2px 3px 5px 0 rgba(0,0,0,.75);
  .sidebar-menu{
    h3{
        color: #fff;
        font-size: 13px;
        text-transform: uppercase;
        margin-top: 18px;
        padding-left: 5px;
    }
    .bg-light{
        background-color: #8d1c1d !important;
        box-shadow: none;
    }
    .navbar-expand-lg {
        flex-flow: column;
        &.navbar{
            align-items: flex-start;
            padding: 0;
        }
        .navbar-collapse{
            align-items: flex-start;
            padding-top: 20px;
            width: 100%;
        }
        .navbar-nav {
            -ms-flex-direction: column;
            flex-direction: column;
            width: 100%;
            a{
                color:#fff;
                line-height: 22px;
                padding: 5px 5px 5px 5px;
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                cursor: pointer;
                &.active{
                    background: #e73525;
                    border-radius: 5px;
                    span{
                        color:#fff;
                    }
                }
                &:hover{
                    background: #e73525;
                    border-radius: 5px;
                    text-decoration: none;
                }
                &:focus{
                    outline:none;
                }
                .sidebar-ic {
                    width: 24px;
                    display: inline-block;
                    text-align: left;
                    margin-left:0;
                    &.sidebar-ic--pos{
                        position: relative;
                        top:-3px;
                    }
                }
                span{
                    display: inline-block;
                    font-size: 14px;
                    margin-left: 6px;
                    vertical-align: middle;
                    width: calc(100% - 31px);
                }
            }
        }
    }
  }
}


aside {
  footer {
    background: #8d1c1d;
    padding: 15px 15px 10px;
    margin: 0;
    position: fixed;
    width: 224px;
    left: 0;
    bottom: 0;
    text-align: center;
    ul {
      margin: 0;
      padding: 0;
      width: 100%;
      li {
        display: block;
        list-style: none;
        margin-top: 1px;
        margin-left: 0;
        text-align: center;
        a {
          color: #fff;
          font-size: 14px;
          line-height: 20px;
          &:hover {
            color: #687986;
            text-decoration: none;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .sidebar-wrap {
    .sidebar-menu {
      .navbar-expand-lg {
        .navbar-nav {
          a {
            span {
              width: calc(100% - 31px);
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.sidebar-wrap .sidebar-menu .navbar-expand-lg .navbar-collapse {
  align-items: flex-start;
  padding-top: 20px;
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .sidebar-wrap {
    position: fixed;
    z-index: 99999999999;
    flex: 0 0 30%;
    max-width: 220px;
    margin-top: 60px;
    min-height: calc(100vh - 55px);
    visibility: visible;
    &.hidden {
      left: -400px;
      visibility: hidden;
    }
    &.show {
      left: 0;
      -webkit-animation: slide 0.5s forwards;
      -webkit-animation-delay: 2s;
      animation: slide 0.5s forwards;
      animation-delay: 2s;
    }
    .navbar-toggler {
      display: none;
    }
    @-webkit-keyframes slide {
      100% {
        left: 0;
      }
    }
    @keyframes slide {
      100% {
        left: 0;
      }
    }

    .sidebar-menu {
      .navbar-expand-lg {
        .navbar-collapse {
          &:not(.show) {
            display: block;
          }
        }
      }
    }
  }
  .overlay {
    &.overlay_hidden {
      display: none;
    }
    &.overlay_show {
      background: rgba(0, 0, 0, 0.6);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9999999;
    }
  }
  .overflow-hidden {
    .widget {
      .table {
        tr {
          td {
            &:first-child {
              width: 300px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .sidebar-wrap {
    flex: 0 0 35%;
  }
}
