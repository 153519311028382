.bold {
    font-weight: 700;
}
.widget--privacy {
    padding-top: 20px;
    padding-bottom: 20px;
}
.privacy-sub--heading {
    color: #0a1c2a;
    font-size: 18px;
    font-weight: 700;
}
.privacy__text {
    font-size: 15px;
    color: #31394d;
    font-weight: 500;
    margin-bottom: 20px;
}
.privacy__text--uppercase {
    text-transform: uppercase;
}
.privacy__listing {
    list-style: none;
    padding-left: 30px;
    li {
        position: relative;
        margin-bottom: 10px;
        &::before {
            content: "";
            width: 7px;
            height: 7px;
            background: #31394d;
            position: absolute;
            border-radius: 50%;
            top: 8px;
            left: -15px;
        }
    }
    .privacy__listing--links {
        padding-left: 30px;
        margin-bottom: 0;
        word-break: break-word;
    }
}
.term-number__listing {
    list-style-type: decimal;
    padding-left: 30px;
    li {
        position: relative;
        margin-bottom: 10px;
        &::before {
           padding-left: 5px;
           content:"";
        }
    }
    .privacy__listing--links {
        padding-left: 30px;
        margin-bottom: 0;
        word-break: break-word;
    }
}
.list-address {
    list-style: none;
    padding-left: 0;
    &.list-address--padding {
        padding-left: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .privacy__listing {  
            padding-left: 24px;
        .privacy__listing--links {
            padding-left: 12px;
        }
    }
    .list-address {
        &.list-address--padding {
            padding-left: 24px;
        }
    }
    
}