input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
.cb-action-wrap{
    .action-filter{
        position: relative;
        top:-5px;
    }
}
.multi-step{
    .progtrckr {
        list-style-type: none;
        padding: 0 0 80px;
        width: 100%;
        display: flex;
        margin: 0;
        li {
            text-align: center;
            cursor: pointer;
            max-width: 25%;
            flex: 0 0 25%;
            position: relative;
            max-height: 100px;
            min-height: 89px;
            top: -20px;
            &.progtrckr-todo{
                cursor: default;
            }
            &:first-child{
                em{
                    display: block;
                    font-weight: 700;
                    padding-left: 0;
                    position: absolute;
                    width: 50%;
                    border-bottom: none;
                    text-indent: -9999px;
                    bottom: 0;
                }
            }
            &:last-child{
                em{
                    display: block;
                    font-weight: 700;
                    padding-left: 0;
                    position: absolute;
                    width: 100%;
                    right:0;
                    border-bottom: 4px solid #fff;
                    text-indent: -9999px;
                    bottom: -4px;
                }
            }
            &:before {
                position: relative;
                bottom: -65px;
                float: left;
                left: 0;
                z-index: 2;
              }
            &.progtrckr-todo {
                color: silver;
                border-bottom: 4px solid silver;
            }
            &.progtrckr-doing {
                color: black;
                border-bottom: 4px solid #CCCCCC;
            }
            &.progtrckr-done {
                color:#8d1c1d;
                border-bottom: 4px solid #8d1c1d;
            }
            em {
                display: none;
                font-weight: 700;
                padding-left: 1rem;
              }
            span {
                padding: 0 1.5rem;
                display: block;
                position: absolute;
                width: 100%;
                bottom: -72px;
                font-size: 15px;
                left: -40%;
                min-height: 50px;
                cursor: default;
                pointer-events: none;
              }
          }
    }

@media (max-width: 650px) {
    .progtrckr {
        padding: 0 0 60px;
        li {
            span {
                display: none;
            }
            em {
                display: inline;
            }
        }
        .progtrckr-doing {
            span {
                display: block;
                text-align: center;
                left: -35%;
                padding:0;
            }
        }
    }
}

  ol.progtrckr li.progtrckr-todo:before {
    content: url(../../assets/images/edit-icon.svg);
    color: silver;
    background-color: #E5E5E5;
    width: 40px;
    line-height: 44px;
    border-radius: 40px;
    height: 40px;
  }

  ol.progtrckr li.progtrckr-todo:hover:before {
    color: #ff4500;
  }

  ol.progtrckr li.progtrckr-doing:before {
    content: url(../../assets/images/edit-icon.svg);
    color: white;
    background-color: #8d1c1d;
    width: 40px;
    line-height: 44px;
    border-radius: 40px;
    height: 40px;
  }

  ol.progtrckr li.progtrckr-doing:hover:before {
    color: #ff4500;
  }

  ol.progtrckr li.progtrckr-done:before {
    content: url(../../assets/images/tick.svg);
    color: white;
    background-color: #8d1c1d;
    width: 40px;
    line-height: 42px;
    border-radius: 40px;
    height: 40px;
  }

  ol.progtrckr li.progtrckr-done:hover:before {
    color: #333;
  }
}
.discount-label {
    width: auto;
    display: flex;
    align-items: center;
    margin-right: 35px;
    .form-control {
        width: 25px;
        margin-right: 10px;
        &:checked{
            background-color: red;
        }
    }
}
.form-group--mb{
    margin-bottom: 30px;
}
.radio.radio-discount {
    width: auto;
    margin-right: 35px;
    .form-control{
        width:auto;
    }
}

.radio.radio-discount label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 35px;
    margin: 0;
}
.radio.radio-discount label::before {
    width: 26px;
    height: 26px;
    top: 0;
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    margin-left: 0;
    border: 1px solid #cccccc;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}
.radio.radio-discount input[type="radio"]:checked + label::after {
    content: "";
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
    background-color: #8d1c1d;
}
.radio.radio-discount label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    margin-left: 0;
    border-radius: 50%;
    background-color: #555555;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    width: 20px;
    height: 20px;
    padding-left: 4px;
    font-size: 20px;
    left: 3px;
    top: 3px;
}
.radio.radio-discount input:checked + label::before {
    border-color: #8d1c1d;
}

.step-progress {
    .progtrckr{
        flex: 0 0 60%;
        max-width: 60%;
        margin-left: 26.5%;
    }
    .steps-content{
        flex: 0 0 65%;
        max-width: 65%;
        margin-left: 20%;
        padding-bottom: 50px;
    }
    .footer-buttons{
        background: #ededed;
        min-height: 90px;
        padding: 20px 30px;
        display:table;
        width: 100%;
        .btn{
            background: #8d1c1d;
            border-color: #8d1c1d;
            padding: 5px 15px;
            border-radius: 3px;
            min-height: 45px;
            &.btn-prev{
                background: #8d1c1d url(../../assets/images/next.svg) no-repeat;
                padding-left: 41px;
                background-position: 9px 11px;
                float: right;
                display: block !important;
                &:hover{
                    background: #7b191a url(../../assets/images/next.svg) no-repeat;
                    border-color: #7b191a;
                    background-position: 9px 11px;
                }
            }
            &.btn-next{
                float: left;
                background: transparent;
                border: 1px solid #BABABA;
                box-shadow: none;
                color: #909090;
                border-radius: 3px;
                padding: 5px 15px;
                min-height: 45px;
                min-width: 125px;
                line-height: 19px;
                &:hover{
                    background: #d5d5d5 !important;
                    color: #656565 !important;
                    border-color: #d5d5d5 !important;
                }
                &:disabled{
                    background: #d5d5d5 !important;
                    color: #656565 !important;
                    border-color: #d5d5d5 !important;
                }
            }
        }
    }
    .select-wrap {
        .form-control{
            padding-right: 49px;
        }
    }
}
.select-wrap{
    position: relative;
    background: #fafafa;
    select{
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        position: relative;
        background: transparent;
    }
    .form-control{
        border: none;
        min-height: 55px;
        border-radius: 0;
        font-size: 17px;
        line-height: 25px;
        color: #333;

        &:focus{
            outline:none;
            box-shadow: none;
            background: transparent;
        }
    }
    &:before{
        background: url(../../assets/images/sel-arrow.svg) no-repeat;
        content: "";
        width: 45px;
        height: 42px;
        position: absolute;
        right: 0;
        background-position: 15px 19px;
        border-left: 1px solid #ccc;
        top: 6px;
    }
}
.info-box__content{
    .content__lft-col{
        color:#31394D;
        font-size: 16px;
    }
    .content__rgt-col{
        color: #666666;
        font-size: 15px;
        font-style: italic;
    }
}
.disp-inline{
    .block-heading{
        span{
            width: calc(100% - 100px);
        }
    }
}
.block-heading__edit {
    color: #8d1c1d !important;
    text-decoration: underline !important;
    font-size: 16px;
    cursor:pointer;
    &:hover{
        text-decoration: none;
        color: #8d1c1d;
    }
}
.disp-inline{
    font-size: 16px;
    margin-bottom: 14px;
    &.disp-inline--break-all{
        word-break: break-all;
    }
    label{
        color: #666;
        margin: 0;
        min-width: 217px;
    }
    span{
        color: #0A1C2A;
        display: inline-block;
        width: calc(100% - 219px);
        vertical-align: top;
    }
    p{
        color: #0A1C2A;
        display: inline-block;
        width: 100%;
        vertical-align: top;
    }
}
.captcha-label {
    font-weight: bold;
    margin: 25px 0 5px 0;
}
.widget--space{
    padding:30px 20px;
    .select-wrap {
        .form-control{
            border-bottom: 1px solid #ccc;
            padding-right: 50px;
        }
    }
}
.info-box__content__desc{
    padding-left: 10px;
}
.block-heading{
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: #0A1C2A;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 40px;
    &.block-heading--mt{
        margin-top:35px;
    }
}
.adm-heading{
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    color: #0A1C2A;
    margin-bottom: 15px;
    justify-content: space-between;
    display: flex;
    position: relative;
    .adm-heading__inner{
        display: inline-block;
        width: calc(100% - 36px);
        word-break: break-word;
        word-wrap: break-word;
        .badge-pndg{
            vertical-align: middle;
            margin-left: 5px;
        }
        .badge-acpt{
            vertical-align: middle;
            margin-left: 5px;
        }
    }
}
.a-card {
    .a-card__label {
        display: block;
        margin: 0;
        padding: 0;
        font-size: 12px;
        color: #333;
    }
    .a-card__content {
        color:  #333333;
        font-size: 18px;
        word-wrap: break-word;
        word-break: break-word;
        line-height: 24px;
    }
}
.form-control{
    &.edge-autofilled{
        background-color:transparent !important;
        background-image:none !important;
        color:#333 !important;
    }
}
.btn--green{
    background: #8d1c1d;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Lato';
    font-weight: 400;
    min-height: 55px;
    border-color: #8d1c1d;
    color: #fff;
    min-width: 180px;
    &:hover{
        color: #fff;
        background: #7b191a;
        border-color: #7b191a;
    }
}
.billing-switch{
    .billing-switch__toggle{
        background: none;
        border: none;
        padding: 0;
        .button {
            position: relative;
            top: 50%;
            width: 47px;
            height: 20px;
            overflow: hidden;
            display: inline-block;
            margin-right: 12px;
            &.rounded {
                border-radius: 100px;
                .layer {
                    border-radius: 100px;
                }
            }
            &.b2 {
                border-radius: 2px;
            }
            .knobs,
            .layer {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
            .checkbox {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                opacity: 0;
                cursor: pointer;
                z-index: 3;
                &:checked {
                    +.knobs:before {
                        content: '';
                        left: 27px;
                        background-color: #fff;
                    }
                    ~.layer {
                        background-color: #0A9C01;
                    }
                }
            }
            .knobs {
                z-index: 2;
                transition: 0.3s ease all;
                &:before {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 4px;
                    width: 16px;
                    height: 10px;
                    font-size: 10px;
                    font-weight: bold;
                    text-align: center;
                    line-height: 1;
                    padding: 8px;
                    background-color: #fff;
                    border-radius: 50%;
                    transition: 0.3s ease all;
                }
            }
            .layer {
                width: 100%;
                background-color: #999;
                transition: 0.3s ease all;
                z-index: 1;
            }
        }
    }
}
.form-group{
    &.form-group--additonal-info{
        max-height: 200px;
        textarea.form-control{
            margin-bottom: 0;
            min-height: 120px;
            margin-top: 25px;
            padding-top: 0;
        }
        .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip{
            top:145px;
        }
    }
}
.action-listing{
    display: flex;
    margin:0;
    padding:0;
    li{
        list-style: none;
    }
}
.cursor-pointer{
    cursor:pointer;
}
.org-detail-link{
   h5{
        display: inline-block;
        width: auto;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }

}
.step-progress {
    &.step-progress--isr{
        .progtrckr{
            flex: 0 0 65%;
            max-width: 65%;
            margin-left: 17%;
        }
        .steps-content {
            flex: 0 0 75%;
            max-width: 75%;
            margin-left: 13%;
        }
    }
}

@media (max-width: 1400px) {
    .step-progress {
        &.step-progress--isr{
            .progtrckr{
                flex: 0 0 58%;
                max-width: 58%;
                margin-left: 20%;
            }
            .steps-content {
                flex: 0 0 90%;
                max-width: 90%;
                margin-left: 6%;
            }
        }


    }
    .step-progress {
        .progtrckr{
            flex: 0 0 70%;
            max-width: 70%;
            margin-left: 21%;
        }
        .steps-content{
            flex: 0 0 80%;
            max-width: 80%;
            margin-left: 10%;
        }
    }
}
@media (max-width: 1024px) {
    .container--pt {
        padding-top: 30px;
    }
}
@media (max-width: 991px) {
    .container--pt {
        padding-top: 60px;
    }
}

@media only screen and (min-width:768px) and (max-width: 991px) {
    .step-progress {
        &.step-progress--isr{
            .progtrckr{
                flex: 0 0 70%;
                max-width: 70%;
                margin-left: 12%;
            }
        }
    }
    .step-progress {
        .progtrckr{
            flex: 0 0 80%;
            max-width: 80%;
            margin-left: 19%;
        }
        .steps-content{
            flex: 0 0 80%;
            max-width: 80%;
            margin-left: 10%;
            &.steps-content--stepsfont-size{
                flex: 0 0 100%;
                max-width: 100%;
                margin-left: 0;
            }
        }
    }
    .disp-inline{
        label{
            min-width:100%;
        }
        span{
            width:100%;
        }
    }
    .checkbox-wrap {
        &.checkbox-footer {
            margin-top: -5px;
            padding-top:0 !important;
            input {
                display: inherit;
            }
        }
    }
}
@media (max-width: 767px) {
    .step-progress {
        .multi-step {
            overflow: hidden;
        }
        .progtrckr{
            flex: 0 0 90%;
            max-width: 90%;
            margin-left: 13%;
        }
        .steps-content{
            flex: 0 0 90%;
            max-width: 90%;
            margin-left: 5%;
        }
    }
    .step-progress {
        &.step-progress--isr {
            .progtrckr {
                flex: 0 0 76%;
                max-width: 76%;
                margin-left: 8%;
            }
            .steps-content{
                h2{
                    font-size: 1.36rem;
                }
            }
        }
    }
    .info-box{
        &.info-box--no-shadow {
            margin: 0 auto 40px;
        }
    }
    .sm--mr-top {
        margin-top: 30px;
    }
}

@media (max-width: 576px) {
    .disp-inline{
        label{
            min-width:100%;
        }
        span{
            width:100%;
        }
    }
    .widget--space {
        padding: 30px 10px;
    }
    .cb-action-wrap {
        .action-filter{
            align-self: flex-start;
        }
        .btn-medium{
            align-self: flex-start;
        }
    }
}
@media (max-width: 470px) {
    .step-progress {
        &.step-progress--isr {
            .progtrckr {
                flex: 0 0 72%;
                max-width: 72%;
                margin-left: 6%;
            }
        }
    }
    .step-progress{
        .progtrckr {
            flex: 0 0 90%;
            max-width: 90%;
            margin-left: 10%;
        }
        .footer-buttons{
           padding: 20px 15px;
        }
    }
    .multi-step {
        .progtrckr{
            li {
                em{
                    color: #fff;
                }
            }
            .progtrckr-doing {
                span {
                   left: -28%;
                }
            }
        }

    }
}
@media (max-width:450px) {
    .step-progress {
        .progtrckr{
            margin-left:10%;
        }
    }
    .field{
        label{
        font-size: 15px;
            &.label--length{
                line-height: 18px;
                font-size: 16px;
                padding: 0 5px;
            }
        }
        input{
            font-size: 14px;
        }
    }
    .a-card {
        .a-card__content{
            font-size: 14px;
        }
    }
    .select-wrap {
        .form-control{
            font-size: 15px;
        }
        .form-controlP{
            font-size: 15px;
        }
    }
    .captcha-label + div div{
        width:100% !important;
    }
    .captcha-label + div iframe{
        width: 133% !important;
        transform: scale(0.75) !important;
        -webkit-transform: scale(0.75) !important;
        transform-origin: 0 0 !important;
        -webkit-transform-origin: 0 0 !important;
    }

}
@media (max-width:360px) {
    .field {
        label{
            font-size: 12px;
            &.label-active{
                font-size: 13px;
            }
        }
    }
    .cb-action-wrap .action-filter{
        margin-bottom: 10px;
    }
}
