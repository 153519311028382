/*  applogic css */
.mck-tab-menu-box{
    .blk-lg-2.move-right {
        display: none !important;
    }
}
.text-transform-none{ text-transform: none; }
.mck-sidebox-launcher .mck-button-launcher{
    width: 70px!important;
    height: 70px!important;
    background-color: #7f2822 !important;
}
.mck-icon-chat {
    top: 24px!important;
    left: 25px!important;
}
#mck-no-conversations {
    padding-top: 60px;
}

.mck-sidebox.mck-box .mck-msg-right .vis ,.mck-sidebox.mck-box  .mck-context-menu{
    display: none !important;
}
#mck-no-more-conversations ,#mck-no-messages,#mck-no-more-messages,#mck-no-conversations{
    display: none !important;
}
/*  applogic css ends */

::-ms-clear{
    display: none;
}
.chat-launcher{
    background-size: 22px 22px;
    box-sizing: content-box;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: 0;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    outline: 0;
    background-position: center;
    background-color: #8d1c1d;
    -webkit-user-drag: none;
    -webkit-transform: scale(1) rotate(360deg);
    transform: scale(1) rotate(360deg);
    -webkit-transition: -webkit-transform 150ms cubic-bezier(.4,0,1,1);
    transition: transform 150ms cubic-bezier(.4,0,1,1);
    .icon-chat{
        background: url('../../assets/images/applogic-chat.png') no-repeat !important;
        background-size: 33px !important;
        width: 48px;
        height: 48px;
        background-position: 14px 15px !important;
    }
}

.form-control:disabled{
    background-color: #fafafa;
    opacity: .65;
    cursor: not-allowed;
}
.form-control:disabled + label{
    opacity: .7;
}
input.form-control:disabled:placeholder-shown + label{
    opacity: .7;
}
.go-top {
    position: fixed;
    cursor: pointer;
    bottom: 171px;
    right: 0;
    color: #ffffff;
    background-color: #8d1c1d;
    z-index: 1;
    width: 50px;
    text-align: center;
    height: 45px;
    border-radius: 7px 0 0 7px;
    line-height: 42px;
    transition: 0.5s;
  }
.mck-sidebox{
    z-index: 99999999 !important;
}
.mck-sidebox.mck-box.fade.in {
    z-index: 9999999999 !important;
}
.mck-sidebox-launcher{
    -webkit-transform: none !important;
    transform: none !important;
}
body{
    &.overflow-hidden{
        position: fixed;
    }

}
.fixed-bar{
    position: fixed;
    bottom:0;
    padding:14px 20px;
    background: #0091ea;
    z-index:99999999;
    width:100%;
    color:#fff;
    font-size: 15px;
    text-align: center;
    &.fixed-bar-hide{
        display: none;
    }
}
.fixed-bar__close{
    position: absolute;
    top:5px;
    right:10px;
    cursor: pointer;
}
.base {
    position: relative;
}
.timebar{
    position:absolute;
    top: 76px;
    padding: 14px 20px;
    background: #c72801;
    z-index: 999;
    width: calc(100% - 224px);
    color: #fff;
    font-size: 15px;
    text-align: center;
    right: 0;
    border-radius: 0;
    display: none;
    &.timebar--visible{
        display: block;
    }
    a{
        color: #fff;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
}
.timebar.timebar--visible + .overlay + .container-fluid .rgt-content {
    margin-top: 35px;
}
.timebar + .overlay + .container-fluid .rgt-content {
    margin-top:0;
}
.row-count {
    margin: 0 0 25px 0;
    label{
        color: #0A1C2A;
        font-size: 16px;
        font-weight: 600;
    }
}
.row-count__value {
    background: #fafafa;
    margin-left: 12px;
}
.field--text-area {
    max-height: 300px;
    margin-bottom: 30px;
    textarea {
        &.form-control {
            padding-top: 0;
            background: #fafafa;
            resize: none;
            min-height: 150px;
        }
    }
}
.field--text-area .form-control.is-invalid~.invalid-feedback{
    top:256px;
    width: inherit;
}
.admins .field--text-area .form-control.is-invalid~.invalid-feedback{
    top:205px;
}

.field--text-area.form-group--error-msg .form-control.is-invalid~.invalid-feedback{
  font-size: 12px;
    width: calc(100% - 73px);
}
.form-group.broadcast-note{
    font-size: .8rem !important;
}
.send-icon {
    padding: 0 0 3px 2px;
}
.readMoreText {
    color: #005FF8 !important;
    display: table;
    font-size: 15px;
    clear: both;
    margin-bottom: 6px;
    &:hover {
        text-decoration: underline;
    }
}
.confirmbox__footer--mrgn {
    margin-bottom: 20px;
}
.btn-cancel--delete {
    background: #e73525;
    color: #ffffff;
    &:hover {
        background: #8d1c1d !important;
        color: #ffffff !important;
        border-color: #8d1c1d !important;
    }
    &:focus {
        background: #8d1c1d;
        color: #fff;
    }
    &:active {
        background: #e73525 !important;
        color: #fff !important;
    }
}
.btn-cancel--update {
    background: #8d1c1d;
    color: #fff;
    &:hover {
        background: #7b191a !important;
        color: #fff !important;
        border-color: #7b191a !important;
    }
    &:focus {
        background: #8d1c1d;
        color: #fff;
    }
    &:active {
        background: #8d1c1d !important;
        color: #fff !important;
    }
}
.confirmbox__footer--update-profile {
    display: flex;
    justify-content: center;
    .btn-cancel {
        background: #ededee;
        margin-right: 15px;
        &:hover, &:active {
            background: #d5d5d5 !important;
            color: #656565 !important;
            border-color: #d5d5d5 !important;
        }
        &.primary-btn{
            &:disabled {
                background: transparent !important;
                border-color: #d5d5d5 !important;
                color: #909090;
              }
        }
    }
    .btn-ok {
        border: 1px solid #bababa;
        box-shadow: 0 2px 6px rgba(0,0,0,.2);
        text-transform: inherit;
    }
}
.media-body--link {
    a {
        color: #31394d;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
.action-listing{
    display: flex;
    margin:0;
    padding:0;
    li{
        list-style: none;
        img{
            cursor:pointer;
        }
    }
}

.table--action-listing .custom-width-column{
    min-width: 130px;
    width:auto;
}
.widget .table.table--action-listing tr td:last-child {
    width: 140px;
}
.text-underline {
    text-decoration: underline;
    cursor: pointer;
}
.widget{
    .tab-responsive{
        &.table--isr-width{
            tr{
                td{
                    &:first-child{
                        min-width: 100px;
                    }
                    width: 15%;
                    h5{
                        min-width: 50px;
                    }
                    .badge-pndg{
                        min-width: auto;
                        text-align: center;
                    }
                }
                th{
                    width:15%;
                }
            }
        }
    }
}
.date_created_col{
    min-width: 116px;
}
table.tab-responsive.table-responsive.table--listing.table--super-admin-width {
    display: table;
}
.help-icon-wrap{
    .field {
        width: calc(100% - 35px);
    }
    .help-icon-wrap__icon{
        margin:14px 0 0 15px;
    }
}
.tooltip-help{
    .tooltip-inner {
        max-width: 320px;
        width:320px;
    }
}

.error-msg{
    color: #dc3545 !important;
}

@media only screen and (max-width:1199px){
    table.tab-responsive.table-responsive.table--listing.table--super-admin-width {
        display: block;
    }
}
@media only screen and (max-width:1024px){
    .timebar {
        position: fixed;
        top: 61px;
        padding: 14px 20px;
        background: #c72801;
        z-index: 9999999;
        width:100%;
        color: #fff;
        font-size: 15px;
        text-align: center;
        right: 0;
        border-radius: 0;
    }
    .timebar + .overlay + .container-fluid .rgt-content {
        margin-top: 0;
    }
    .timebar.timebar--visible + .overlay + .container-fluid .rgt-content {
        margin-top: 48px;
    }
    .widget{
        .tab-responsive{
            &.table--isr-width{
                tr{
                    td{
                        padding: 0.7rem 0.2rem;
                    }
                }
            }
            td{
                font-size: 14px;
            }
            .action-listing li{
                img {
                    margin-right: 5px !important;
                    width: 16px;
                }
            }
        }
        .badge--f-size {
            font-size: 12px;
            padding: 3px 5px;
        }
    }
}
@media only screen and (max-width:991px){
    .widget {
        .table-bordered {
            td{
                h5{
                    min-width: auto;
                }
            }
        }
        .date_created_col{
            min-width: auto;
        }
        .table tr td .media{
            min-width: auto;
        }
    }
}
@media only screen and (max-width:767px){
    .mb-guardian-email{
        word-break: break-all;
    }
    .media--mrgn {
        min-width: auto !important;
    }
    .widget .table.table--action-listing tr td:last-child {
        width: 100%;
    }
    .widget{
        .tab-responsive{
            &.table--isr-width{
                tr{
                    td{
                        padding:0.7rem 1rem 0.7rem 148px;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 750px){
    .widget {
        .tab-responsive{
            &.table--isr-width {
                tr{
                    td{
                        width: 100%;
                    }
                }
            }
        }
        .table-contact {
            .custom-width-column{
                width: 100%;
            }
        }
    }
    .widget .table.table--super-admin-width tr td:nth-last-child(2), .widget .table.table--super-admin-width tr .table-bordered th:nth-last-child(2), .widget .table-bordered .table.table--super-admin-width tr th:nth-last-child(2){
        width: 100% !important;
    }
}

@media only screen and (max-width: 500px){
    .timebar {
        padding: 9px 20px;
        font-size: 14px;
    }
}
@media (max-width: 480px) {
    .confirmbox__footer--mrgn {
        .btn-cancel {
            min-width: 90px;
            margin-bottom: 12px;
        }
    }
    .confirmbox__footer--update-profile {
        display: flex;
        justify-content: flex-start;
        flex-direction: column-reverse;
        .btn {
            margin: 0 auto 10px;
        }
    }
}
