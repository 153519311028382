.info-box {
  &.info-box--profile {
    .info-box__header {
      min-height: 200px;
      .info-box__uplaod {
        img {
          width: auto;
        }
      }
      img {
        max-width: 100%;
      }
    }
    .info-box__content {
      h4 {
        span {
          font-size: 28px;
          color: #243746;
          word-break: break-word;
          word-wrap: break-word;
          line-height: 26px;
        }
        .info-box--profile-username {
          font-size: 16px;
          color: rgba(10, 28, 42, 0.6);
        }
      }
      p {
        font-size: 18px;
      }
    }
    .form-fields {
      .btn-ternary {
        padding: 5px 15px;
        .btn__icon {
          top: 0;
        }
      }
      .btn-cancel {
        min-width: 100px;
        margin-left: 15px;
      }
      .btn-primary {
        margin-right: 0;
      }
    }
    .form-group {
      &.field {
        margin-bottom: 20px;
      }
      margin-bottom: 0;
    }
    .info-box__content__txt {
      margin: 0 0 20px;
    }
  }
}
.info-box__content {
  .btn-cancel {
    min-width: 100px;
  }
}
.slab-header {
  background: #f6f9fc;
  justify-content: space-between;
  display: flex;
  min-height: 49px;
  align-items: center;
  padding: 5px 10px 5px 20px;
  .slab-header__heading {
    color: #0a1c2a;
    font-size: 18px;
    font-weight: 700;
    .slab-header__heading__subheading {
      font-size: 14px;
      font-style: italic;
      color: #646f79;
      font-weight: normal;
      display: inline-block;
      margin-left: 10px;
    }
  }
  a {
    &.edit-icon {
      border-radius: 50%;
      width: 45px;
      height: 45px;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      text-align: center;
      line-height: 39px;
      &:hover {
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
}
.badge-acpt {
  background: #0a9c01;
  color: #fff;
  padding: 3px 8px;
  font-size: 11px;
  border-radius: 3px;
}
.badge-pndg{
    background: #FFB722;
    color:#fff;
    padding:3px 8px;
    font-size: 11px;
    border-radius:3px;
    min-width: auto;
    display: inline-block;
}
.badge-resend {
  background: #00cbbc;
  color: #fff;
  padding: 3px 8px;
  font-size: 11px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
  &.badge--disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
}
.badge-reject {
  background: #dd2c00;
  color: #fff;
  padding: 3px 8px;
  font-size: 11px;
  border-radius: 3px;
}
.badge--f-size {
  font-size: 14px;
}
.slab-content {
  padding: 20px;
  .slab-content__media {
    display: flex;
    flex-wrap: wrap;
  }
  .field {
    max-width: 400px;
    input {
      max-width: 400px;
    }
  }
  .slab-content__block {
    display: inline-block;
    vertical-align: top;
    margin-left: 35px;
    &:first-child {
      margin-left: 0;
    }
    .label-group {
      label {
        color: #000;
        font-size: 16px;
        display: inline-block;
      }
      .mail-group__badge {
        font-style: normal;
        font-size: 11px;
        background: #005ff8;
        color: #fff;
        border-radius: 2px;
        padding: 3px 5px;
        line-height: 12px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
      }
      span {
        color: #0a1c2a;
        font-size: 16px;
        font-weight: 600;
        margin-left: 6px;
        &.badge-pndg {
          @extend .badge-pndg;
          margin-right: 5px;
        }
        &.badge-acpt {
          @extend .badge-acpt;
          margin-right: 5px;
        }
      }
      .edit-icon {
        cursor: pointer;
        margin-right: 5px;
        padding: 6px 8px;
        line-height: 16px;
        display: inline-block;
        cursor: pointer;
        &:hover {
          text-decoration: none;
          opacity: 1;
          background: rgba(25, 118, 210, 0.08);
          border-radius: 5px;
        }
      }
      .delete-icon {
        cursor: pointer;
        margin-right: 0px;
        padding: 6px 8px;
        line-height: 16px;
        margin-left: 0;
        display: inline-block;
        &:hover {
          text-decoration: none;
          opacity: 1;
          background: rgba(25, 118, 210, 0.08);
          border-radius: 5px;
        }
      }
    }
    .alert-info {
      padding: 5px 10px;
    }
  }
  .media {
    margin-right: 0;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    align-self: baseline;
    &.media--wrap {
      padding: 0;
      .im--plft {
        padding-left: 10px;
        padding-top: 10px;
      }
      .media-body {
        padding: 10px 10px 0 10px;
      }
    }
    img {
      border-radius: 5px;
    }
    h5 {
      color: #0a1c2a;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 3px;
      display: flex;
      justify-content: space-between;
      position: relative;
      span {
        display: inline-block;
        width: calc(100% - 47px);
      }
      .edit-icon {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        text-align: center;
        line-height: 30px;
        position: absolute;
        top: -7px;
        right: -8px;
        cursor: pointer;
        &:hover {
          background: rgba(25, 118, 210, 0.08);
        }
      }
    }
    p {
      color: #687986;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
  .slab-content__listing {
    padding: 0;
    margin: 5px 0 0 0;
    li {
      list-style: none;
      display: inline-block;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      img {
        max-width: 35px;
      }
    }
  }
  .prf-upload {
    max-width: 155px;
    margin-bottom: 20px;
    text-align: center;
    .prf-upload__input {
      position: relative;
      input {
        position: absolute;
        background: transparent;
        border: none;
        -webkit-appearance: none;
        width: 120px;
        overflow: hidden;
        opacity: 0;
        margin-top: 6px;
      }
      .prf-upload__link {
        color: #005ff8;
        font-size: 16px;
        display: inline-block;
        margin-top: 8px;
      }
    }
  }
  .addition-block {
    border-top: 1px solid #ccc;
    padding-top: 23px;
    margin-top: 5px;
    .select-wrap {
      position: relative;
      background: #fafafa;
      select {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        position: relative;
        background: transparent;
      }
      .form-control {
        border: none;
        border-bottom: 1px solid #ccc;
        min-height: 55px;
        border-radius: 0;
        font-size: 17px;
        line-height: 25px;
        color: #333;
        margin-bottom: 20px;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &:before {
        background: url(../../assets/images/sel-arrow.svg) no-repeat;
        content: "";
        width: 45px;
        height: 42px;
        position: absolute;
        right: 0;
        background-position: 15px 19px;
        border-left: 1px solid #ccc;
        top: 6px;
      }
    }
  }
  .prf-upload__im {
    max-width: 155px;
    max-height: 155px;
    min-height: 155px;
    border: 1px solid #cecece;
    border-radius: 5px;
    line-height: 145px;
    text-align: center;
    overflow: hidden;
    img {
      max-width: 100%;
    }
  }
}
.form-fields {
  .form-group {
    margin-bottom: 30px;
  }
  .form-control {
    background: #fafafa;
    border: none;
    border-radius: 0;
  }
  .btn-ternary {
    padding: 5px 25px 5px 15px;
  }
}
.row--space {
  .slab-content {
    .btn {
      margin-right: 13px;
    }
  }
}
.add-linkbtn {
  font-size: 16px;
  color: #005ff8;
  padding: 6px 8px;
  display: inline-flex;
  cursor: pointer;
  align-self: baseline;
  i {
    border-bottom: 1px solid transparent;
    font-style: normal;
    color: #005ff8;
    padding-bottom: 2px;
    padding-left: 4px;
  }
  .add-linkbtn__inner {
    padding-left: 4px;
  }
  &:hover {
    text-decoration: none;
    opacity: 1;
    background: rgba(25, 118, 210, 0.08);
    border-radius: 5px;
  }
}
.react-datepicker__input-container {
  input {
    background: #fafafa url(../../assets/images/calendar-ic.png) no-repeat 97%
      3px !important;
  }
}
.field--badge {
  .mail-group__badge {
    display: inline-block;
    align-self: flex-end;
    font-style: normal;
    font-size: 11px;
    background: #005ff8;
    color: #fff;
    border-radius: 2px;
    padding: 3px 5px;
    line-height: 12px;
    position: relative;
    top: 17px;
    right: 6px;
  }
}
.content__rgt-col--position {
  position: relative;
  top: -12px;
}
.lg-wrap {
  display: flex;
  justify-content: space-between;
  background: #f4f4f4;
  border-radius: 0 0 5px 5px;
  margin: 10px 0 0;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  align-items: center;
  width: 100%;
  .lg-wrap__label {
    text-transform: uppercase;
    font-size: 11px;
    color: #666666;
    margin-right: 10px;
    font-weight: 500;
  }
  .lg-wrap__im-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .add-lg {
      width: 25px;
      height: 25px;
      display: flex;
      border: 2px solid #646f79;
      text-align: center;
      border-radius: 50%;
      line-height: 25px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 0.5rem;
      .mr-2 {
        margin-right: 0 !important;
        border: none;
      }
    }
    img {
      max-width: 100%;
      vertical-align: top;
    }
  }
}
.popover{
    &.fade{
        opacity: 1 !important;
        max-width:140px;
        text-align: center;
        min-width:130px;
    }
}
.lg-wrap__im-hover {
  margin-right: 0.5rem;
  width: 25px;
  overflow: hidden;
  border-radius: 50%;
  height: 25px;
  border: 2px solid #646f79;
  margin-bottom: 0.5rem;
  &.im-wrap__invite-pndg {
    border-color: #ffb722;
  }
  &.im-wrap__invite-acpt {
    border-color: #0a9c01;
  }
  &.lg-wrap__im-hover--accepted {
    &:hover {
      background: none;
      img {
        opacity: 1;
      }
    }
  }
  &:hover {
    background: rgba(0, 0, 0, 0.5) url("../../assets/images/edit-icon.svg")
      no-repeat;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-position: 3px 2px;
    img {
      opacity: 0.3;
    }
  }
}
.mdl-topbar {
  background: #f7f7f7;
  padding: 10px 25px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  span {
    color: rgba(10, 28, 42, 0.5);
    font-size: 18px;
    display: inline-block;
    margin-left: 15px;
    width: calc(100% - 68px);
    vertical-align: middle;
  }
}
.mdl-table-outer {
  border-top: 1px solid #ccc;
  margin: 40px -30px 0;
  padding: 0 30px;
  max-height: 400px;
  overflow-y: auto;
  h3 {
    font-size: 18px;
    color: #0a1c2a;
    margin: 20px 0 10px;
    font-weight: bold;
    line-height: 23px;
  }
}
.mdl-table {
  margin-bottom: 0;
  tr {
    td {
      border: 1px solid #ebedf4;
      border-left: none;
      border-right: none;
      vertical-align: middle;
      font-size: 12px;
      color: #687986;
      padding: 7px 15px;
      border-top: 0;
      &:first-child {
        width: 300px;
      }
      &:last-child {
        text-align: right;
      }
      h5 {
        margin-bottom: 0;
        font-size: 15px;
        color: #31394d;
        .add-linkbtn {
          position: relative;
          top: 3px;
          margin-left: 5px;
        }
      }
    }
  }
  button {
    background: #e0e1e2;
    width: 50px;
    height: 40px;
    border-radius: 5px;
    border-color: #e0e1e2;
    box-shadow: none;
    line-height: 45px;
    padding: 0;
    &:hover {
      box-shadow: none;
      background-color: #cccccc !important;
      border-color: #e0e1e2;
    }
    &:active {
      box-shadow: none;
      border-color: #e0e1e2;
      background-color: #e0e1e2;
    }
  }
  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: #e0e1e2;
    border-color: #e0e1e2;
  }
  button[type="submit"] {
    background: #e0e1e2 url("../../assets/images/modal-ok-btn.png") no-repeat;
    background-position: 13px 10px;
    margin-right: 15px;
    &.btn-loader {
      background-position: 0 10px;
      .spinner-border {
        margin-left: 20px;
        color: #7b7b7b !important;
        position: relative;
        top: -3px;
      }
      &:disabled {
        border-color: #e0e1e2;
      }
    }
  }
  button[type="reset"] {
    background: #e0e1e2 url("../../assets/images/modal-delete.png") no-repeat;
    background-position: 13px 10px;
    &:disabled {
      border-color: #e0e1e2;
    }
  }
}

.mdl-loader {
  width: 68px;
  display: inline-flex;
  align-self: center;
  .offset-sm-1.mt-3.mb-4.text-center.col-sm-10 {
    margin: 0 !important;
  }
}
.fixed-topbar {
  display: none;
}
.body-topbar {
  .topbar {
    &.navbar {
      margin-top: 45px;
    }
  }
  .fixed-topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #0091ea;
    text-align: center;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    font-size: 16px;
    z-index: 999;
    height: 45px;
    display: flex;
    color: #fff;
    font-weight: 500;
    font-family: "Lato", sans-serif;
    span {
      background-color: #fff;
      text-transform: uppercase;
      padding: 5px 13px;
      color: #333;
      font-size: 14px;
      font-weight: 600;
      border-radius: 2rem;
      cursor: pointer;
      margin-left: 20px;
    }
  }
  @media only screen and (max-width: 991px) {
    .sidebar-wrap {
      margin-top: 110px;
    }
    .rgt-content {
      padding-top: 112px;
    }
  }
  @media only screen and (max-width: 450px) {
    .fixed-topbar {
      flex-direction: column;
      height: 80px;
    }
    .sidebar-wrap {
      margin-top: 143px;
    }
    .rgt-content {
      padding-top: 145px;
    }
    .topbar {
      &.navbar {
        margin-top: 80px;
      }
    }
  }
}
.smloader-min-height {
  min-height: 250px;
  display: flex;
  align-items: center;
}
.btn--reset.add-linkbtn {
  background: transparent !important;
  border: none;
  width: auto;
  height: auto;
  padding: 6px 8px;
  &:hover {
    text-decoration: none;
    opacity: 1;
    background: rgba(25, 118, 210, 0.08) !important;
    border-radius: 5px;
  }
}
.border-semirounded--width-70 {
  width: 70px;
}
.media-body--width-calc {
  width: calc(100% - 90px);
  word-wrap: break-word;
}
@media only screen and (max-width: 1550px) {
  .profileim-box {
    .btn-cancel {
      min-width: 95px;
    }
  }
}
@media only screen and (max-width: 1450px) {
  .info-box {
    &.info-box--profile {
      .info-box__content {
        h4 {
          span {
            font-size: 22px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1370px) and (min-width: 1200px) {
  .info-box {
    &.info-box--profile {
      .btn-wrap {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        max-width: 100px;
      }
      .form-fields {
        .btn-cancel {
          margin-left: 0;
          margin-top: 15px;
        }
      }
    }
  }
  .profileim-box {
    .btn-cancel {
      min-width: 68px;
      padding: 5px 8px;
      font-size: 15px;
    }
    .btn-ternary {
      min-width: 80px;
      padding: 5px 6px;
      font-size: 15px;
    }
  }
  .guardian-list--media {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1400px) {
  .profile-main-wrapper{
    .profileim-box {
      &.col-lg-3 {
        flex: 0 0 30%;
        max-width: 30%;
      }
    }
    .md--full-width {
      &.col-md-12 {
        flex: 0 0 70%;
        max-width: 70%;
      }
    }
  }
}
@media only screen and (max-width: 1399px) and (min-width: 1200px) {
  .profile-main-wrapper {
    .profileim-box {
      &.col-lg-3 {
        flex: 0 0 34%;
        max-width: 34%;
      }
    }

    .md--full-width {
      &.col-md-12 {
        flex: 0 0 66%;
        max-width: 66%;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .profileim-box {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media only screen and (max-width: 1150px) and (min-width: 992px) {
  .guardian-list--media {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .mdl-table {
    tr {
      td {
        &:first-child {
          width: 260px;
        }
        &:last-child {
          width: 150px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .profileim-box {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .slab-content {
    display: block;
    .slab-content__block {
      margin-left: 0;
      display: block;
    }
    .prf-upload {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 25px;
    }
    .col-sm-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .field--badge {
    .mail-group__badge {
      top: 8px;
    }
  }
  .form-fields {
    .row {
      &.w-100 {
        width: auto !important;
      }
    }
  }
  .mdl-table {
    tr {
      td {
        &:last-child {
          text-align: left;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .profileim-box {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .slab-header {
    flex-direction: row;
    flex-wrap: wrap;
    .slab-header__heading {
      width: 100%;
    }
    .add-linkbtn {
      width: auto;
      flex-direction: row;
      margin: 6px 0 0;
      display: inline-block;
    }
  }
}
@media only screen and (max-width: 500px) {
  .slab-content {
    .media {
      display: block;
      .lg-wrap {
        img {
          display: inline-block;
          margin: 0 !important;
        }
      }
      img {
        margin: 0 auto 15px !important;
        display: table;
      }
      .slab-content__listing {
        img {
          display: block;
          margin: 0 !important;
        }
      }
    }
    .slab-content__block {
      .label-group {
        margin-bottom: 10px;
        label {
          display: inline-block;
          margin-bottom: 0px;
        }
        span {
          margin-left: 0;
          display: block;
          &.badge-pndg {
            margin-right: 10px;
            display: inline-block;
          }
          &.badge-acpt {
            margin-right: 10px;
            display: inline-block;
          }
        }
      }
    }
  }
  .form-fields {
    .btn-ternary {
      padding: 5px 15px 5px 10px;
    }
  }
  .slab-content {
    .media {
      min-width: 100%;
      .edit-icon {
        img {
          margin-bottom: 0 !important;
          position: relative;
          top: 6px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 900px;
  }
}
