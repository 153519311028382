/***** footer *****/
footer{
    background: #E1E6EB;
    padding: 20px 20px 20px 30px;
    margin-top: 90px;
    margin-left: -30px;
    margin-right: -15px;
    ul {
        display: inline-block;
        margin: 0 0 0 65px;
        padding: 0;
        vertical-align: middle;
        li{
            display: inline-block;
            list-style: none;
            margin-left: 30px;
            &:first-child{
                margin-left:0;
            }
            a{
                color: #687986;
                font-size: 16px;
                line-height: 25px;
                transition: all ease-in-out .3;
                &:hover{
                    color:#39424a;
                    text-decoration: none;                    
                }
            }
        }
    }
}
.footer-wrap{
    background: transparent;
    padding: 0;
    margin-top: 30px;
    margin-left: 0;
    margin-right:0;
    ul{
        padding: 0;
        list-style: none;
        margin: 0 0 15px;
        text-align: center;
        width: 100%;
        li{
            &:first-child {
                margin-left: 0;
            }           
        }       
    }
    p{
        text-align: center;
        width: 100%;
        margin: 0 0 25px;
        color: #a2a2a2;
    }
}