.panel-wrapper{
    padding: 50px 80px;
    width: 100%;
    background: #fff;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0,0,0,.11);
    margin:0 auto;
    max-width: 700px;
    @media only screen and (max-width:1160px) and (min-width:992px){
        padding: 50px 45px;
    }
    .field{
        margin-bottom: 40px;
    }
}
.panel-heading{
    max-width: 700px;
    margin: 0 auto;
}

.student-data-img{
    img{
        border-radius:5px;
        max-width:100px; 
    }
}
.data-row {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .data-row__col {
        max-width: 50%;
        flex: 0 0 50%;
    }
    label {
        text-transform: uppercase;
        font-size: 13px;
        margin-bottom: 0;
        font-weight: 700;
        width:100%;
    }
    .data-row__value {
        font-size: 16px;
        color: #848484;
        width: 100%;
    }
}
.heading--sm{
    font-weight:700;
    span {
        color: #757575;
        font-size: 20px;
    }    
}
.detail-link{
    color: #005FF8;
    cursor: pointer;
}
