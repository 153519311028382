.dsh-topouter{
    background: #e7e7e7;
    padding:35px 25px 45px;
    margin: 0 -30px;
    border-bottom: 5px solid #e73525;
}
.dsh-topouter__heading{
    color:#000;
    margin-bottom:25px;
}
.disabled-link{
    pointer-events: none;
    cursor: default;
}
.dsh-topouter-col{
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 15px;
    padding-left: 15px;
    margin:15px 0;
}
.dsh-topouter__block {
    display: flex;
    box-shadow: none;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background: transparent;
    border-color: transparent;
    padding: 0;
    min-height: 0;   
    &:hover{
        text-decoration: none;
        box-shadow:none;
        background: transparent;
        border-color: transparent;
    }
    p {
        color: #000;
        font-size: 20px;
        line-height: 25px;
        font-weight: bold;
        margin-bottom: 0;
        margin-top: 5px;
    }
    .dsh-topouter__block__icon-bg{
        width:96px;
        height:96px;
        background: #fff;
        border-radius:50%;
        margin:0 auto;
        text-align: center;
        line-height: 90px;
    }
}
.dsh-topouter__block.btn-primary:not(:disabled):not(.disabled).active, .dsh-topouter__block.btn-primary:not(:disabled):not(.disabled):active, .show>.dsh-topouter__block.btn-primary.dropdown-toggle{
    background: transparent !important;
    border-color: transparent !important
}
.dsh-content{
    h3{
        color: #0A1C2A;
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 35px;       
    }
    .col-md-3{
        &.col-sm-6 {
            margin-bottom: 20px;
        }
    }
    a {
        text-decoration: none;
    }
}
.dsh-content__col {
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    text-align: center;
    padding: 40px 20px;
    img{
        margin:0 auto 15px;
    }
    p {
        font-size: 48px;
        line-height: 55px;
        text-align: center;
        font-weight: 700;
        color: #0A1C2A;
        margin: 0;
    }
    .dsh-content__col__desc {
        color: #687986;
        font-size: 14px;
        text-align: center;
        line-height: 20px;
    }
}
.dsh-content {
    margin: 50px 0 35px;
}
@media only screen and (min-width:1570px){
    .dsh-content{
        padding:0 40px;
        h3{
            margin-left:-20px;
        }
        .col-sm-3{            
            padding: 0 40px;
        }
        .row{
            margin:0 -40px
        }
    }
}
@media only screen and (max-width:1400px){
    .dsh-content__col{
        p{
            font-size: 38px;
            line-height: 50px;
        }
    }
}
@media only screen and (max-width:1199px){
    .dsh-content {
        .col-md-3{
            &.col-sm-6 {
                flex: 0 0 50%;
                max-width: 50%;
                margin-bottom: 20px;
            }
        }
    }
    .dsh-topouter-col {
        flex: 0 0 33.333367%;
        max-width: 33.333367%;
        padding-right: 15px;
        padding-left: 15px;
        margin: 20px 0 20px 0;
        &.ml-3{
            &.mr-3{
                margin-left:0 !important;
                margin-right:0 !important;
            }
        }
    }
}
@media only screen and (max-width:991px){
    .dsh-topouter{
        margin:0 -15px;
    }
}
@media only screen and (max-width:767px){    
    .dsh-topouter__block{
        p{
            font-size: 20px;
        }
    }
    .dsh-content {
        h3{
            font-size: 20px;
        }
    }
}
@media only screen and (max-width:576px){
    .dsh-content {
        .col-md-3{
            &.col-sm-6 {
                flex: 0 0 100%;
                max-width:100%;
            }
        }
    }
    .dsh-topouter__block{
        min-height: 100px;
        p{
            font-size: 18px;
        }
    }
    .dsh-topouter-col {
        flex: 0 0 100%;
        max-width: 100%;         
    }
}