// .table-contact tbody {
//     // display:block;
//     height:800px;
//     overflow:auto;
// }

// .table-contact thead, .table-contact tbody tr {
//     display:table;
//     width:100%;
//     table-layout:fixed;/* even columns width , fix width of table too*/
// }

// .table-contact thead {
//     width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
// }

// table.table-contact {
//     width:100%;
// }

div:focus {
    outline: none;
}
.unordered-listing {
    max-width: 100%;
    word-break: break-word;
    padding: 0 20px;
    margin-top: 25px;
    li{
        list-style: none;
        font-size: 18px;
    }
    .unordered-listing__heading{
        font-size: 20px;
        font-family: 'Quicksand';
        margin-bottom: 5px;
        strong{
            border-bottom: 1px solid #444444;
            padding-bottom: 4px;
            margin-bottom: 5px;
            display: inline-block;
        }
    }
}
.block-pos{
   display: flex;
    position: absolute !important;
    right: 18px;
    top: -39px;
    width: calc(100% - 30px);
    margin-left:30px;
    flex-direction: row;
    justify-content: flex-end;
    &.block-pos--position{
        right:18px;
    }
    &.block-pos--position-top{
        top: -56px;
    }
    &.block-pos--single-elem{
        top: -51px;
    }
    a {
        display: inline-block;
        margin-right: 10px;
        &:last-child{
            margin-right:0;
        }
      }
      .outline-circle {
        border: 3px solid #8d1c1d;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
}
.c-filter-outer{
    display: flex;
    justify-content: flex-end;
    .wgt-filters {
        position: relative;
        display: flex;
        margin-right: 30px;
        align-self: center;
        label{
            color: #687986;
            font-size: 16px;
            margin: 0;
            align-self: center;
        }
        .sel-wrap{
            border:1px solid #A5A5A5;
            border-radius:5px;
            background: transparent url(../../assets/images/selectbox-arrow.svg) no-repeat;
            background-position: 95%;
            display: inline-block;
            margin-left:15px;
            position: relative;
            align-self: flex-start;
            .sel-wrap__label{
                position:absolute;
                top:6px;
                left:6px;
            }
            select{
                -webkit-appearance: none;
                appearance: none;
                &.form-control {
                    display: inline-block;
                    width: auto;
                    min-width: 110px;
                border:none;
                    background: transparent;
                }
            }
            .sel-dropdown{
                &.show {
                    .btn-primary{
                        &.dropdown-toggle{
                            color:#162B3B !important;
                            padding-left:10px;
                        }
                    }
                }
                .dropdown-item {
                    &:active{
                        color: #16181b;
                        text-decoration: none;
                        background-color: #f8f9fa;
                    }
                }
                .dropdown-toggle{
                    &:after{
                        border:none;
                    }
                }
                .btn-primary{
                    background: transparent;
                    padding:0;
                    border:none;
                    color:#162B3B;
                    padding: .375rem .75rem;
                    height: calc(1.5em + .75rem + 2px);
                    box-shadow: none;
                    max-width: 150px;
                    min-width: 150px;
                    text-align: left;
                    padding-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 24px;
                }
                .dropdown-menu{
                    min-width:170px;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
                    border: none;
                    top: 3px !important;
                }
                .css-1pahdxg-control{
                    min-width: 170px;
                    background: transparent;
                    max-width:203px;
                    padding-right: 31px;
                    border: none;
                    box-shadow: none;
                    .css-1okebmr-indicatorSeparator{
                        width:0;
                    }
                }
                .css-tlfecz-indicatorContainer {
                    padding: 0;
                    display: none;
                }
                .css-1gtu0rj-indicatorContainer {
                    padding: 0;
                    display: none;
                }
                .css-yk16xz-control{
                    min-width: 170px;
                    background: transparent;
                    max-width:203px;
                    padding-right: 31px;
                    border: none;
                    .css-1okebmr-indicatorSeparator{
                        width:0;
                    }
                }

            }
        }
    }
    .wgt-btnwrap {
        a {
            display: inline-block;
            margin-right: 10px;
            vertical-align: top;
            &:last-child{
                margin-right:0;
            }
        }
        .outline-circle {
            border: 3px solid #8d1c1d;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.cb-action-wrap{
    .outline-circle-wrap {
        display: inline-block;
        margin-right: 10px;
        &:last-child{
            margin-right:0;
        }
    }
    .outline-circle {
        border: 3px solid #8d1c1d;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.action-filter{
    display: inline-block;
    margin-right: 20px;
    .sel-wrap{
        border:1px solid #A5A5A5;
        border-radius:5px;
        background: transparent url(../../assets/images/selectbox-arrow.svg) no-repeat;
        background-position: 95%;
        display: inline-block;
        margin-left:15px;
        position: relative;
        align-self: flex-start;
        .sel-wrap__label{
            position:absolute;
            top:6px;
            left:6px;
        }
        select{
            -webkit-appearance: none;
            appearance: none;
            &.form-control {
                display: inline-block;
                width: auto;
                min-width: 110px;
            border:none;
                background: transparent;
            }
        }
        .sel-dropdown{
            &.show {
                .btn-primary{
                    &.dropdown-toggle{
                        color:#162B3B !important;
                        padding-left:10px;
                    }
                }
            }
            .dropdown-toggle{
                &:after{
                    border:none;
                }
            }
            .btn-primary{
                background: transparent;
                padding:0;
                border:none;
                color:#162B3B;
                padding: .375rem .75rem;
                height: calc(1.5em + .75rem + 2px);
                box-shadow: none;
                max-width: 150px;
                min-width: 150px;
                text-align: left;
                padding-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 24px;
            }
            .dropdown-menu{
                min-width:170px;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
                border: none;
                top: 3px !important;
            }
        }
    }
}
/******* multi select *****/
span.multiselect-native-select{position:relative}span.multiselect-native-select select{border:0!important;clip:rect(0 0 0 0)!important;height:1px!important;margin:-1px -1px -1px -3px!important;overflow:hidden!important;padding:0!important;position:absolute!important;width:1px!important;left:50%;top:30px}.multiselect-container{position:absolute;list-style-type:none;margin:0;padding:0}.multiselect-container .input-group{margin:5px}.multiselect-container .multiselect-reset .input-group{width:93%}.multiselect-container>li{padding:0}.multiselect-container>li>a.multiselect-all label{font-weight:700}.multiselect-container>li.multiselect-group label{margin:0;padding:3px 20px;height:100%;font-weight:700}.multiselect-container>li.multiselect-group-clickable label{cursor:pointer}.multiselect-container>li>a{padding:0}.multiselect-container>li>a>label{margin:0;height:100%;cursor:pointer;font-weight:400;padding:3px 20px 3px 40px}.multiselect-container>li>a>label.checkbox,.multiselect-container>li>a>label.radio{margin:0}.multiselect-container>li>a>label>input[type=checkbox]{margin-bottom:5px}.btn-group>.btn-group:nth-child(2)>.multiselect.btn{border-top-left-radius:4px;border-bottom-left-radius:4px}.form-inline .multiselect-container label.checkbox,.form-inline .multiselect-container label.radio{padding:3px 20px 3px 40px}.form-inline .multiselect-container li a label.checkbox input[type=checkbox],.form-inline .multiselect-container li a label.radio input[type=radio]{margin-left:-20px;margin-right:0}
/* multi select end  */
.multiselect-native-select{
    .dropdown-toggle{
        &:after{
            border:none;
        }
    }
    .btn{
        padding: .375rem .75rem;
        height: calc(1.5em + .75rem + 2px);
        vertical-align: inherit;
        box-shadow: none;
        max-width: 190px;
        min-width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        padding-left: 52px;
        .caret{
            display: none;
        }
    }
    .dropdown-menu{
        min-width:200px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
        border: none;
        top: 3px !important;
    }
    .multiselect-container {
        li {
            &.multiselect-group{
                a{
                    padding:2px 0;
                    cursor: default;
                    &:hover{
                        background-color: transparent;
                        cursor: text;
                    }
                }
            }
            a {
                display: block;
                padding:5px 0;
                &:hover{
                    background-color: #f8f9fa;
                }
                label {
                    padding: 0 20px 0 20px;
                }
            }
        }
    }
}
.multiselect-selected-text {
    display: inline-block;
    line-height: 24px;
    vertical-align: top;
}
.icbg-circle{
    width: 24px;
    height: 24px;
    font-size: 14px;
    color: #fff;
    line-height: 24px;
    border-radius: 50%;
    background: #005FF8;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    margin-right: 8px;
}
.widget-inner {
    padding: 15px;
}
.upload-wrap {
    border: 1px dashed #A5A5A5;
    width: 100%;
    min-height: 520px;
    border-radius: 5px;
    justify-content: center;
    align-content: center;
    display: flex;
    line-height: 520px;
    .upload-wrap__inner{
        display: inline-block;
        line-height: normal;
        align-self: center;
        .upload-wrap__inner__box{
            cursor: pointer;
        }
        .input-group{
            max-width: 138px;
            width: 138px;
            align-self: center;
            margin: 15px auto 10px;
            input{
                background: #E0E1E2;
                font-size: 14px;
                color:rgba(0,0,0,.60);
                border:none;
                cursor: pointer;
                &:focus{
                    box-shadow: none !important;
                }
            }
            .input-group-text{
                border:none;
                background: #D7D7D8;
            }
            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #000;
                opacity: .8; /* Firefox */
                font-size: 14px;
                font-weight:bold;
              }

              :-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #000;
              }

              ::-ms-input-placeholder { /* Microsoft Edge */
                color: #000;
              }
        }
        p{
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            margin:0;
            text-align: center;
        }
        span{
            color:#3A5060;
            font-size: 14px;
            text-align: center;
            display: block;
        }
    }
}
.heading-group{
    h5{
        font-size: 16px;
        margin-bottom: 5px;
    }
    p{
        font-size:14px;
        color:rgba(0,0,0,.8);
    }
}
.alert {
    display: flex;
    line-height: normal;
    padding: 12px 15px;
    &.alert--success{
        border-color:#59DE52;
        background: rgba(147,244,142,.11);
        color:rgba(30,86,31,1);
        .alert-content{
            p{
                color:rgba(30,86,31,.80);
            }
        }
    }
    &.alert--danger{
        border-color:#DD2C00;
        background: #FFF6F6;
        color:#973937;
    }
    .alert-content{
        padding-left:15px;
        width: calc(100% - 46px);
        .alert__heading{
            font-size: 18px;
            font-weight:700;
            padding-bottom:6px;
        }
        p{
            margin:0 0 4px;
            font-size:14px;
            line-height: 20px;
        }
        ul{
            padding:0;
            margin:0;
            li{
                margin:0 0 6px;
                list-style: none;
                font-size:14px;
            }
        }
    }
    .alert__icon {
        display: inline-block;
        width: 46px;
        height: 46px;
        background: #ccc;
        border-radius: 50%;
        line-height: 40px;
        text-align: center;
        &.alert__icon--success{
            background:#12CD07;
        }
        &.alert__icon--danger{
            background:#DD2C00;
        }
        &.alert__icon--warning{
            background: #856404;
        }
    }
}
.grid-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
    .grid-col {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
        padding:0 15px;
        margin-bottom: 30px;
        .grid-col__inner{
            border-radius: 5px;
            border:1px solid #7b191a;
            padding-top: 16px;

            &.grid-col__inner--grey{
                border-color:#687986;
            }
            &.grid-col__inner--red{
                border-color:#DD2C00;
            }
        }
        .grid-col__inner__link{
            margin: 0 auto 10px;
            color: #005FF8;
            font-size: 12px;
            align-self: center;
            display: table;
            border-bottom: 1px solid #005FF8;
        }
        .grid-col__inner__label {
            color: #DD2C00;
            font-size: 14px;
            text-align: center;
            padding-bottom: 10px;
            &.grid-col__inner__label--success{
                color:#3A5060;
            }
            img{
                padding-right: 2px;
            }
            span{
                vertical-align: middle;
                line-height: 20px;
            }
        }
        label{
            color: #546979;
            font-size: 14px;
            padding-left: 15px;
        }
        .name-wrap {
            min-height: 54px;
            padding-left: 15px;
            line-height: 14px;
            padding-top: 14px;
            label{
                color: #3A5060;
                display: block;
                font-size: 14px;
                font-weight: bold;
                padding-left: 0;
                margin: 0;
                line-height: 16px;
            }
            span{
                color: #687986;
                font-size: 10px;
                display: inline-block;
                line-height: 12px;
            }
        }
        ul{
            padding:0;
            margin:0;
            max-height: 300px;
            overflow: auto;
            li{
                list-style: none;
                font-size: 12px;
                padding: 8px 10px;
                border-bottom:1px solid #687986;
                background:#F3F4F8;
                min-height: 35px;
                word-break: break-word;
                &:last-child{
                    border-bottom:none;
                    border-radius: 0 0 5px 5px;
                }
            }
            &.greenbg{
                li{
                    background: #8d1c1d;
                    border-color:#7b191a;
                    color: #fff;
                }
            }
        }

        .select-wrap{
            position: relative;
            background: #fafafa;
            margin:0 15px;
            select{
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                position: relative;
                background: transparent;
            }
            .form-control{
                border:none;
                border-bottom:1px solid #eaeae8;
                min-height: 37px;
                border-radius: 0;
                font-size: 14px;
                line-height: 25px;
                color:#333;
                margin-bottom: 15px;
                padding-right: 35px;
                &:focus{
                    outline:none;
                    box-shadow: none;
                }
            }
            &:before{
                background: url(../../assets/images/sel-arrow.svg) no-repeat;
                content: "";
                width: 31px;
                height: 26px;
                position: absolute;
                right: 0;
                background-position: 8px 10px;
                border-left: 1px solid #ccc;
                top: 6px;
            }
        }
    }
}
.matched-col {
    margin:50px 0 35px;
    ul {
        margin: 0;
        padding: 0;
        li{
            list-style: none;
            display: inline-block;
            margin-left: 35px;
            margin-bottom: 10px;
            &:first-child{
                margin-left:0;
            }
            &:nth-child(2){
                margin-left:20px;
            }
            i{
                font-style: normal;
                color: #546979;
                font-size: 14px;
            }
            strong{
                font-weight: 700;
                color:#000;
                font-size: 16px;
            }
            .checkwrap {
                .checkwrap__label{
                    cursor:default;
                }
            }
        }
    }
}
.btn__sendic{
    img{
        position: relative;
        top: -2px;
        left: 2px;
    }
}
/****  error page */
.error-page {
    padding:75px 0;
    h1 {
        font-size: 200px;
        font-weight: bold;
        color: #0A1C2A;
        line-height: 200px;
        margin-bottom: 20px;
    }
    h3{
        font-size: 32px;
        font-weight: bold;
        line-height: 32px;
        text-transform: uppercase;
    }
    .error-code{
        font-size:20px;
        font-weight: 500;
        margin: 80px 0 10px;
    }
    .btn{
        padding: 10px 25px;
    }
}
.border-rounded {
    border-radius: 50%;
}

.border-semirounded{
    border-radius:5px;
    width:50px;
}
.inline-flex{
    &.media{
        display: inline-flex;
        margin-right:15px;
    }
    .im-rounded-outer{
        margin-bottom: 10px;
    }
}
// .inline-flex.media + .inline-flex.media {
//     margin-left:15px;
// }

.sel-wrap{
    border:1px solid #A5A5A5;
    border-radius:5px;
    background: transparent url(../../assets/images/selectbox-arrow.svg) no-repeat;
    background-position: 95%;
    display: inline-block;
    margin-left:15px;
    position: relative;
    align-self: flex-start;
    .sel-wrap__label{
        position:absolute;
        top:6px;
        left:6px;
    }
    select{
        -webkit-appearance: none;
        appearance: none;
        &.form-control {
            display: inline-block;
            width: auto;
            min-width: 110px;
        border:none;
            background: transparent;
        }
    }
}
.modal-body--min-height{
    min-height:100px;
}
.widget {
    .table--first-col-width{
        tr{
            td{
                &:first-child{
                    width:auto;
                }
            }
        }
    }
}
.error-listing {
    position: relative;
    margin: -20px 0 30px;
    padding: 0 0 0 56px;
    li {
        list-style: disc;
        text-align: left;
        font-size: 16px;
        margin-bottom: 6px;
    }
}
.fixed-actionbar {
    position: fixed;
    width: 100%;
    padding: 15px 38px;
    left: 0;
    top: 0;
    background: #ececec;
    min-height: 75px;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    .fixed-actionbar__count{
        font-weight: bold;
        align-self: center;
    }
    &.fixed-actionbar--more-btns{
        @media only screen and (max-width:370px){
            padding: 10px 15px;
                    &.wgt-btnwrap{
                    .btn{
                        &.btn-primary{
                            &.ml-3 {
                                margin-left: 5px !important;
                            }
                        }
                    }
                }
                .btn-cancel {
                    min-width: 75px;
                }
                .btn-icon{
                    min-width:30px;
                    &.btn-icon--delete{
                        background-position: 5px 4px;
                    }
                }
                .btn-icon.btn-icon--delete:hover, .btn-icon.btn-icon--delete:active, .btn-icon.btn-icon--delete.btn-primary:not(:disabled):not(.disabled):active{
                    background-position: 5px -38px;
                }
                .btn {
                    font-size: 12px;
                    line-height: 20px;
                }
            }


    }
    .btn{
        box-shadow: none;
        &:hover{
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }
    }
}
.mb-table-row{
    display: none;
}
.alert--layout{
    display: block;
    p{
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.selected-count {
    color: #687986;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0;
    min-width: 170px;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle{
    background-color: transparent;
    color: #212529;
    border-color:#ccc;
}
.field{
    .btn-outline-secondary{
        &:hover{
            background-color: transparent;
            color: #212529;
        }
    }
    .input-group{
        // .btn{
        //     border: none;
        //     box-shadow: none;
        //     padding: 11px 10px;
        //     border-right: 1px solid #ccc;
        //     margin-right: 10px;
        //     color: #212529;
        //     min-width: 115px;
        //     max-width: 115px;
        // }
        .select-text {
            position: absolute;
            font-size: 11px;
            top: 8px;
            left: 14px;
        }
        select {
            border: none;
            background: transparent;
            padding: 15px 0 0 10px;
            max-width: 122px;
            margin-right: 14px;
            font-size: 13px;
            color: #495057;
            &:focus{
                border: none;
                box-shadow: none;
                background: transparent;
                outline: none;
            }
        }
        label{
            left: 135px;
            z-index: 10;
        }
        .form-control{
            margin-top: 15px;
            padding-left: 0;
        }
        .dropdown-menu{
            min-width: 6rem;
            padding: .2rem 0;
            .dropdown-item{
                &:active{
                    color: #16181b;
                    text-decoration: none;
                    background-color: #f8f9fa;
                }

            }
        }
    }
}
.member-group-listing{
    list-style: none;
    padding: 0;
    margin: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    li{
        margin-bottom: 10px;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;
        em{
            font-style: normal;
            margin-left: 7px;
        }
    }
}
.modal-link{
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}
.first-name-link {
    cursor: pointer;
    text-decoration: underline;
}
.more-action-btn{
    &.dropdown{
        display: inline-block;
        vertical-align: top;
        .dropdown-menu{
            right: 0 !important;
            left: auto !important;
            transform: none !important;
            top: 100% !important;
            padding: 0;
        }
    }
    .btn-primary {
        padding: 0;
        background: transparent;
        border: none;
        box-shadow: none;
        &:after{
            content:"";
            border: none;
            display: none
        }
       &:active{
            background: transparent !important;
            border: none !important;
       }
    }
    &.show{
        .btn-primary {
            background: transparent !important;
            border: none !important;
            box-shadow: none !important;
        }
    }
    .dropdown-item{
        color: #696F74;
        margin-right: 0 !important;
        padding: .5rem 1rem;
        border-bottom: 1px solid #EEEEEE;
        &:active{
            background: transparent !important;
            color: #696F74 !important;
        }
        em{
            font-style: normal;
            margin-left: 8px;
        }
        &:first-child{
            &:hover{
                border-radius:5px 5px 0 0;
            }
        }
        &:last-child{
            &:hover{
                border-radius:0 0 5px 5px;
            }
        }
        &.more-action-btn__links--disable{
            cursor: not-allowed;
            opacity: .6;
        }

    }
}
.copy-text-sm{
    position: absolute;
    top: 24px;
    left: -8px;
    font-size: 14px;
    color: #8d1c1d;
    @media only screen and (max-width:750px) and (min-width:200px) {
        top: 1px;
        right: -66px;
    }
}
.disabled-btn{
    pointer-events: none;
    opacity: .7;
}
@media only screen and (max-width:1710px) and (min-width:1451px){
    .c-filter-outer {
        // flex-direction: column-reverse;
        .wgt-btnwrap{
            align-self: flex-end;
            // margin-bottom: 15px;
        }
        .wgt-filters{
            align-self: center;
        }
    }
}
@media only screen and (max-width:1450px){
    .block-pos{
        right:15px;
        &.block-pos--position{
            right:15px;
        }
    }
    .grid-wrapper {
        .grid-col {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
            padding:0 30px;
        }
    }
    .c-filter-outer {
        // flex-direction: column-reverse;
        .wgt-btnwrap{
            align-self: flex-end;
            // margin-bottom: 15px;
        }
        .wgt-filters{
            align-self: center;
        }
    }
}
@media only screen and (max-width:1430px){
    .block-pos {
        right: 15px;
        margin: 20px 0 0 0;
        position: static !important;
        width: 100%;
        &.block-pos--position{
            position: absolute !important;
            margin: 0;
            flex-direction: row;
            width: calc(100% - 30px);
        }
        &.block-pos--single-elem{
            position: absolute !important;
            margin: 0;
            flex-direction: row;
            width: calc(100% - 30px);
        }
    }
    .grid-wrapper {
        margin:0 -20px;
        .grid-col {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
            padding:0 20px;
            ul{
                li{
                    word-break: break-word;
                }
            }
        }
    }
    .c-filter-outer {
        flex-direction: column-reverse;
        .wgt-btnwrap {
            margin-bottom: 0;
        }
        .wgt-filters {
            align-self: flex-end;
            margin-right: 0;
            margin-top: 15px;
            .sel-wrap{
                .sel-dropdown{
                    .css-yk16xz-control{
                        max-width: 175px;
                    }
                }
            }
        }
    }


}
@media only screen and (max-width:1199px){
    .block-pos{
        &.block-pos--position {
            position: static !important;
            margin: 20px 0 0 0;
            width: 100%;
        }
        &.block-pos--single-elem{
            &.block-pos--single-staticelem{
                position: static !important;
                width:100%;
                margin-top:20px;
            }
        }
    }
}
@media only screen and (max-width:1100px){
    .block-pos {
        flex-direction: column-reverse;

        .wgt-filters {
            position: relative;
            display: inline-block;
            margin-right: 0;
            align-self: flex-end;
            margin: 20px 0 0 0;
            .sel-wrap{
                .sel-dropdown{
                    .css-yk16xz-control{
                        max-width: 210px;
                    }
                }
            }
        }
        .wgt-btnwrap {
            align-self: flex-end;
        }
    }
    .grid-wrapper {
        margin:0 -20px;
        .grid-col {
            -ms-flex: 0 0 33.3337%;
            flex: 0 0 33.3337%;
            max-width: 33.3337%;
            padding:0 20px;
            margin-bottom: 25px;
            ul{
                li{
                    word-break: break-word;
                }
            }
        }
    }
    .wgt-btnwrap{
        .btn-medium{
            padding:5px 8px;
            font-size:14px;
        }
        .btn-secondary{
            padding:5px 8px;
        }
        .btn{
            &.mr-3{
                margin-right:8px !important;
            }
        }
    }
}
@media only screen and (max-width: 1024px) {
    .fixed-actionbar {
        z-index: 99999999;
        padding: 6px 10px;
        min-height: 65px;
        .btn {
            margin-bottom: 10px;
        }
    }

}
@media only screen and (max-width:767px){
    .mb-table-row{
        display: block;
    }
    .member-group-listing{
        li{
            margin-bottom: 10px;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0 15px;
        }
    }
    .grid-wrapper {
        margin:0 -20px;
        .grid-col {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            padding:0 20px;
            margin-bottom: 25px;
        }
    }
    .matched-col{
        ul {
            li{
                display: block;
                &:first-child {
                    margin-left:0;
                }
                &:nth-child(2){
                    margin-left:0;
                }
                margin-left:0;
                margin-bottom: 10px;
            }
        }
    }
    .text-right.rgt-link {
        margin-bottom: 10px;
        a{
            font-size: 15px;
        }
    }
    .block-pos {
        &.block-pos--position{
            position: static !important;
             margin: 20px 0 0 0;
            flex-direction: row;
            .btn{
                font-size: 14px;
            }
        }
        .outline-circle{
            width: 45px;
            height: 45px;
            img{
                width: 20px;
            }
        }
        &.block-pos--single-elem{
            top:-35px;
        }
    }
    .c-filter-outer {
        // flex-direction: column-reverse;
        .wgt-btnwrap{
            align-self: flex-start;
            margin-bottom: 15px;
            margin-top:15px;
            a {
                margin-bottom: 10px;
            }
        }
        .wgt-filters{
            align-self: flex-start;
        }
        .fixed-actionbar {
            &.wgt-btnwrap{
                margin-top:0;
            }
        }
    }

    .error-page {
        padding:75px 20px;
        h1 {
            font-size: 140px;
            line-height: 140px;
        }
        h3 {
            font-size: 28px;
        }
    }
    .field{
        .input-group{
            .select-text {
                left: 5px;
            }
           select {
                padding: 16px 0 0 2px;
                max-width: 95px;
                margin-right: 10px;
                font-size: 13px;
            }
            .form-control{
                min-height: 35px;
            }
            label{
                width: 67%;
                left: 106px;
            }
        }
    }
}
.modal-table{
    &.table-bordered {
        border: none;
    }
    thead{
        tr{
            th{
                color: #8d1c1d;
                font-size: 14px;
                background: #f6f9fc;
                padding: 0.7rem 0.75rem;
                border-left: none;
                border-right: none;
            }
        }
    }
    tbody{
        tr{
            td{
                border: 1px solid #ebedf4;
                border-left: none;
                border-right: none;
                vertical-align: middle;
                font-size: 15px;
                color: #31394d;
                padding: 0.7rem 0.75rem;
            }
        }
    }
}
.modal-table tr:last-child td, .modal-table tr:last-child th {
    border: none;
}
.deactivated-user-view {
    max-height: 410px;
    overflow-y: auto;
    list-style-type: square;
    width:100%;
    flex-wrap: wrap;
    display: flex;
    list-style: inside;
    padding: 0 0 0 20px;
}
.deactivated-user-view-wrap p {
    padding-left: 20px;
    font-weight: bold;
}

.deactivated-user-view-wrap {
    background: #f8f8f8;
    border: 1px solid #dedede;
    padding: 15px 15px 15px 5px;
    border-radius: 5px;
    margin-bottom: 25px;
}
ul.deactivated-user-view li {
    margin-bottom: 5px;
    max-width: 33%;
    flex: 0 0 33%;
}
@media only screen and (max-width: 800px) {
    ul.deactivated-user-view li {
        max-width: 50%;
        flex: 0 0 50%;
    }
}
@media only screen and (max-width: 748px) {
    .wgt-btnwrap{
        .btn {
            &.btn-primary{
                margin-bottom: 10px !important;
            }
        }
        // &.fixed-actionbar--mrgn{
        //     .btn {
        //         &.btn-primary{
        //             margin-bottom: 0 !important;
        //         }
        //     }
        // }
    }

}
@media only screen and (max-width:640px){
    .wgt-btnwrap{
        .btn {
            &.btn-primary{
                margin:0 8px 10px 0 !important;
            }
        }
    }
    .fixed-actionbar{
        &.wgt-btnwrap{
            .btn {
                &.btn-primary{
                    // margin:0 !important;
                    &.ml-3{
                        margin-left: 10px !important;
                    }
                    &.btn-cancel{
                        margin-left:0 !important;
                        margin-right: 0 !important;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:576px){
    .block-pos{
        &.block-pos--position{
            flex-direction: row;
            margin:10px 0 0 0;
            .btn{
                max-width:230px;
                margin-top:10px;
                display: block !important;
                text-align: left;
                width:100%;
                &.btn-icon--delete{
                    text-align: center;
                    max-width: 45px;
                    margin-bottom: 10px;
                }
                &.mr-4{
                    margin-right:0 !important;
                }
                &:last-child{
                    margin-bottom: 0;
                    margin-left:0 !important;
                }
            }
            .mr-4{
                margin-right:8px !important;
            }

        }
        .outline-circle{
            margin-bottom: 10px;
        }
    }
    .fixed-actionbar{
        justify-content: flex-end;
        &.wgt-btnwrap{
            .fixed-actionbar__count{
                display: none;
            }
        }
        // .btn-secondary, .btn-cancel {
        //     padding: 5px;
        //     min-height: 41px;
        // }
    }
    .text-right.rgt-link{
       text-align: left !important;
       margin:0 0 15px;
    }
    .heading-group {
        margin-bottom: 20px;
        p{
            margin-bottom: 0;
        }
    }
    .mob-col-orders{
        .col-sm-5{
            order:2;
        }
        .col-sm-7{
            order:1;
        }
    }
    ul.deactivated-user-view li {
        max-width: 100%;
        flex: 0 0 100%;
    }
}
@media only screen and (max-width:550px){
    .c-filter-outer {
        .wgt-filters{
            display: block;
            .sel-wrap{
                margin:12px 0 0 0;
                width:100%;
                .sel-dropdown {
                    .btn-primary{
                        min-width: 100%;
                        max-width:100%;
                    }
                    .css-yk16xz-control{
                        max-width:100%;
                    }
                    .css-1pahdxg-control{
                        max-width:100%;
                    }
                }
            }
        }
    }

}
@media only screen and (max-width:500px){
    .block-pos {
        flex-direction: column-reverse;

        .wgt-filters {
            margin: 12px 0 0 0;
            display: block;
            width: 100%;
            label{
                margin:0 0 5px;
            }
            .sel-wrap{
                display: block;
                margin-left: 0;
                width: 100%;
                margin-bottom: 12px;
                select.form-control {
                    display: block;
                    width: 100%;
                }
                .multiselect-native-select{
                    .btn-group {
                        width: 100%;
                        .btn{
                            max-width: 100%;
                            min-width: 100%;
                        }
                    }
                    .dropdown-menu{
                        min-width: 100%;
                    }
                }
                .sel-dropdown{
                    .dropdown-menu{
                        min-width:100%;
                    }
                    .btn-primary{
                        max-width: 100%;
                        min-width: 100%;
                    }
                }
            }
        }
        .wgt-btnwrap {
            align-self: flex-end;
            .btn {
                margin-bottom: 12px;
            }

        }
    }
    .alert {
        .alert-content{
            ul{
                li{
                    word-break: break-word;
                }
            }
        }
    }
    .grid-wrapper {
        margin:0 -20px;
        .grid-col {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding:0 20px;
            margin-bottom: 25px;
        }
    }

}
@media only screen and (max-width:480px) {
    .fixed-actionbar{
        padding: 10px;
        min-height: 65px;
        .btn-secondary, .btn-cancel {
            padding: 5px;
            min-height: 41px;
        }
    }
}
@media only screen and (max-width:450px){
    .block-pos{
        &.block-pos--single-elem {
            position: static !important;
            margin: 20px 0 0;
            flex-direction: row;
            justify-content: flex-start;
        }
    }
    .btn-cancel{
        min-width: 85px;
    }
}
@media only screen and (max-width:420px){
    .btn-ternary{
        padding: 5px 9px;
    }

    .btn-delete{
        min-width: 85px;
    }
    .block-pos {
        &.block-pos--position{
            flex-direction: row;
            margin:10px 0 0 0;
            .btn{
                max-width:230px;
                margin-top:10px;
                display: block !important;
                text-align: left;
                &.btn-icon--delete{
                    text-align: center;
                }
                &.mr-4{
                    margin-right:0 !important;
                }
            }
        }
    }
    .fixed-actionbar{
        .btn {
            font-size: 13px;
            line-height: 20px;
        }
    }
}
