.bg-light {
  background: #fff !important;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
.btn-primary {
  &:focus {
    box-shadow: none;
  }
}
button {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}
.btn {
  &:focus {
    box-shadow: none !important;
    outline: none;
  }
}

.btn-secondary {
  background: #8d1c1d;
  border-color: #8d1c1d;
  padding: 5px 15px;
  border-radius: 3px;
  min-height: 45px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background: #7b191a;
    border-color: #7b191a;
  }
  &:disabled {
    background: #7b191a;
    border-color: #7b191a;
    opacity: 0.65;
  }
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #8d1c1d;
  border-color: #8d1c1d;
}
.btn-ternary:not(:disabled):not(.disabled).active,
.btn-ternary:not(:disabled):not(.disabled):active,
.show > .btn-ternary.dropdown-toggle {
  color: #fff !important;
  background: #01beb0;
  border-color: #01beb0;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background: #8d1c1d;
  border-color: #8d1c1d;
}
.btn__position {
  align-self: flex-end;
  position: absolute !important;
  right: 69px;
  top: -56px;
}
.btn-ternary {
  background: #8d1c1d;
  border-color: #8d1c1d;
  padding: 5px 15px;
  border-radius: 3px;
  min-height: 45px;
  color: #fff;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background: #8d1c1d;
  border-color: #8d1c1d;
    color: #fff;
  }
  &:disabled {
    background: #8d1c1d;
    border-color: #8d1c1d;
    cursor: not-allowed;
  }
}
.btn-ok {
  background: #8d1c1d;
  border: 1px solid #8d1c1d;
  box-shadow: none;
  color: #fff;
  border-radius: 3px;
  padding: 5px 15px;
  min-height: 45px;
  min-width: 125px;
  line-height: 19px;
  font-weight: 600;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-transform: capitalize;
  &:hover {
    background: #7b191a;
    border-color: #7b191a;
  }
  &:focus {
    background: #8d1c1d;
    border-color: #8d1c1d;
  }
  &:active {
    background: #8d1c1d !important;
    border-color: #8d1c1d !important;
  }
  &:disabled {
    opacity: 0.7;
  }
}
.btn {
  &:disabled {
    cursor: not-allowed;
  }
}
.btn-activate {
  @extend .btn-ok;
  &:hover {
    background: #7b191a;
    border-color: #7b191a;
  }
  &:focus {
    background: #8d1c1d;
    border-color: #8d1c1d;
  }
  &:active {
    background: #8d1c1d !important;
    border-color: #8d1c1d !important;
  }
  &:disabled {
    opacity: 0.7;
  }
}
.btn-delete {
  background: #e73525;
  border: 1px solid #e73525;
  box-shadow: none;
  color: #fff;
  border-radius: 3px;
  padding: 5px 15px;
  min-height: 45px;
  min-width: 125px;
  line-height: 19px;
  font-weight: 600;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background: #8d1c1d !important;
    border-color: #8d1c1d !important;
  }
  &:focus {
    background: #e73525;
    border: 1px solid #e73525;
  }
  &:active {
    background: #e73525 !important;
    border: 1px solid #e73525 !important;
  }
  &:disabled {
    opacity: 0.7;
  }
}
.btn-cancel {
  background: transparent;
  border: 1px solid #bababa;
  box-shadow: none;
  color: #909090;
  border-radius: 3px;
  padding: 5px 15px;
  min-height: 45px;
  min-width: 125px;
  line-height: 19px;
  font-weight: 600;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background: #d5d5d5 !important;
    color: #656565 !important;
    border-color: #d5d5d5 !important;
  }
  &:active {
    background: #d5d5d5 !important;
    color: #656565 !important;
    border-color: #d5d5d5 !important;
  }
  &:focus {
    background: transparent;
    border: 1px solid #bababa;
    color: #909090;
  }
  &:disabled {
    background: transparent;
    border-color: #bababa;
    color: #909090;
  }
  &.btn-cancel--width-auto {
    min-width: 70px;
  }
  &.btn-primary{
    &:disabled {
      background: transparent !important;
      border-color: #bababa !important;
      color: #909090;
    }
  }
}
.btn-medium {
  padding: 5px 13px;
  min-height: 45px;
  border-radius: 3px;
  &.btn-medium--space {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.btn-rounded {
  border-radius: 30px;
}

.btn-icon {
  padding: 5px 5px;
  min-height: 40px;
  border-radius: 3px;
  min-width: 45px;
  background: #e0e1e2;
  border-color: #e0e1e2;
  text-align: center;
  box-shadow: none;
  img {
    margin: 0 auto;
  }
  &.btn-icon--delete {
    max-height: 47px;
    background: #e0e1e2 url(../../assets/images/multi-delete.png);
    background-repeat: no-repeat;
    background-position: 12px 4px;
    &:hover {
      background: #f8d7da url(../../assets/images/multi-delete.png);
      border-color: #f8d7da;
      background-repeat: no-repeat;
      background-position: 12px -38px;
    }
    &:active {
      @extend :hover;
    }
  }
  &.btn-primary:not(:disabled):not(.disabled):active {
    @extend :hover;
  }
}
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:active {
    &:after {
      transform: scale(0, 0);
      opacity: 0.3;
      transition: 0s;
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }
}

.btn__icon {
  display: inline-block;
  // vertical-align: middle;
  height: 25px;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  line-height: 22px;
  margin-right: 7px;
  .btn__icon__space {
    margin-left: 2px;
  }
  &.btn__icon--green {
    background: #077001;
  }
  &.btn__icon--red {
    background: #630404;
  }
  &.btn__icon--sea-green {
    background: #009287;
  }
}
.delete__icon {
  height: 46px;
  width: 46px;
  line-height: 46px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  img {
    width: 30px;
  }
  &:hover {
    background: rgba(221, 44, 0, 0.3);
  }
}
.org-logo {
  img {
    max-height: 48px;
    max-width: 300px;
  }
}
.top-right {
  display: flex;
  align-items: center;
}
.hamburger-ic {
  display: none;
}
.notification {
  background: url("../../assets/images/notify.svg") no-repeat;
  display: inline-block;
  width: 30px;
  height: 36px;
  background-size: 30px;
  background-position: 0px 6px;
  position: relative;
  .notification__count {
    background: #dd2c00;
    height: 18px;
    width: 18px;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    line-height: 17px;
    font-size: 10px;
    color: #fff;
    top: 3px;
    right: -5px;
    font-weight: 700;
    border: 1px solid #fff;
  }
}
.notification-heading {
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  color: #0a1c2a;
  display: block;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  padding: 0 20px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
}
.dropdown-overflow {
  max-height: 268px;
  overflow-y: auto;
}
.header-search {
  .form-control {
    padding: 0.375rem 0.75rem;
    background-clip: padding-box;
    border: none;
    border-radius: 0;
    min-width: 250px;
    background: #f5f5fc url("../../assets/images/header-search-ic.svg")
      no-repeat;
    background-position: 5px 8px;
    padding-left: 35px;
    color: #969696;
    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}
.topbar {
  .dropdown {
    text-align: center;
    &.prf_user {
      .media {
        padding-right: 30px;
        .media-body {
          line-height: 20px;
          text-align: left;
          h5 {
            font-size: 16px;
            color: #3a5060;
            line-height: 20px;
            margin-bottom: 5px;
            max-width: 500px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .im-semirounded-outer {
        line-height: 46px;
        background: #eaeaea;
        border: 1px solid #ccc;
        border: 1px solid #e0e0e0;
      }
      .dropdown-toggle {
        &:after {
          border: none;
          background: url("../../assets/images/prfarrow_icon.svg") no-repeat;
          width: 20px;
          height: 14px;
          background-position: 0;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
        img {
          max-width: 50px;
          max-height: 50px;
        }
      }
      .dropdown-menu {
        min-width: 13rem;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
        border: none;
        padding: 0 0 13px;
        .dropdown-item {
          font-size: 15px;
          color: #0a1c2a;
          padding: 8px 35px 8px 40px;
          white-space: normal;
          border-bottom: 0;
          &.active {
            background: url(../../assets/images/arrow-dropdown.svg) no-repeat;
            background-position: 13px 12px;
          }
          &:first-child {
            &:hover {
              border-radius: 5px 5px 0 0;
              background-color: #f8f9fa;
            }
          }
          &:last-child {
            &:hover {
              border-radius: 0 0 5px 5px;
              background-color: #f8f9fa;
            }
          }
          &.dropdown-item--setting {
            background: url(../../assets/images/setting-ic.svg) no-repeat;
            background-position: 11px 8px;
            &:hover {
              background-color: #f8f9fa;
            }
          }
          &.dropdown-item--switch-ic{
            background: url(../../assets/images/switch-icon.svg) no-repeat;
            background-position: 11px 13px;
            &:hover {
              background-color: #f8f9fa;
            }
          }
          &.dropdown-item--logout {
            background: url(../../assets/images/logout-ic.svg) no-repeat;
            background-position: 12px 9px;
          }
        }
      }
    }
    .dropdown-menu {
      left: inherit;
      right: 0;
      padding: 0;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
      border-color: #ebebeb;
      min-width: 20rem;
      top: 56px;
      .dropdown-item {
        font-size: 15px;
        color: rgba(10, 28, 42, 0.64);
        padding: 11px 20px;
        white-space: normal;
        border-bottom: 1px solid #ebebeb;
        p {
          margin-bottom: 0;
        }
        &:active {
          text-decoration: none;
          background-color: #fff;
        }
        &:last-child {
          border: 0;
        }
        &:first-child {
          &:hover {
            border-radius: 5px 5px 0 0;
          }
        }
        &:last-child {
          &:hover {
            border-radius: 0 0 5px 5px;
          }
        }
      }
    }
    .btn-primary {
      background-color: transparent !important;
      border: none;
      box-shadow: none;
      padding: 0;
      margin-left: 0;
      &.dropdown-toggle {
        &:focus {
          box-shadow: none;
        }
      }
    }
    .dropdown-toggle {
      &:after {
        border: none;
      }
    }
  }
  .badge-wrap {
    display: inline-block;
    width: auto;
    max-width: 116px;
    margin-right: 6px;
    text-align: center;
    margin-bottom: 5px;
  }
  .t-badge {
    background: #f0f6ff;
    color: #2b62b4;
    border-radius: 2px;
    border: 1px solid #2b62b4;
    display: inline-block;
    font-size: 11px;
    padding: 2px 8px;
    line-height: 14px;
    margin-bottom: 7px;
    &.t-badge--coach {
      background: #f2fffe;
      color: #008c82;
      border-color: #00cbbc;
    }
    &.t-badge--admin {
      background: #f2f2f2;
      color: #243746;
      border-color: #546979;
    }
    &.t-badge--lg {
      background: #f3fff2;
      color: #0a9c01;
      border-color: #12cd07;
    }
  }
}
.rgt-content {
  padding-left: 30px;
  width: calc(100% - 224px);
  padding-right: 30px;
  padding-bottom: 110px;
  .breadcrumb {
    background: transparent;
    padding: 0;
    margin: 2px 0 0 0;
    max-width: 25%;
    flex: 0 0 25%;
    padding-right: 15px;
    display: block;
    .breadcrumb-item {
      word-wrap: break-word;
      word-break: break-word;
      display: inline;
    }
  }
}
.show {
  &.dropdown-outer {
    .btn-primary {
      &.dropdown-toggle {
        color: #0a1c2a !important;
      }
    }
  }
}
.dropdown-outer {
  &.dropdown-outer--mr-top {
    .btn-primary {
      margin-top: 45px;
    }
  }
  .btn-primary:not(:disabled):not(.disabled):active {
    color: #0a1c2a !important;
  }
  .btn-primary {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    padding: 0;
    margin-left: 22px;
    color: #0a1c2a;
    font-size: 28px;
    font-weight: 700;
    margin: 0 0 0 0;
    &.dropdown-toggle {
      &:focus {
        box-shadow: none;
      }
    }
    &:after {
      border: none;
      background: url("../../assets/images/prfarrow_icon.svg") no-repeat;
      width: 17px;
      height: 10px;
      background-position: -2px -7px;
      margin: 0 0 0 4px;
      position: relative;
      align-self: center;
    }
  }
  &.show {
    .btn-primary {
      &.dropdown-toggle {
        color: #0a1c2a;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .dropdown-toggle {
    white-space: normal;
    word-break: break-word;
    word-wrap: break-word;
    padding-right: 15px;
    display: flex;
  }
  .dropdown-outer__content {
    display: inline-block;
    text-align: left;
    font-size: 28px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 300px;
  }
  .dropdown-menu {
    padding: 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    border-color: #ebebeb;
    min-width: 270px;
    top: 5px !important;
    max-height: 250px;
    overflow-y: auto;
    .dropdown-item {
      font-size: 15px;
      color: rgba(10, 28, 42, 0.64);
      padding: 8px 14px;
      white-space: normal;
      border-bottom: 1px solid #ebebeb;
      word-break: break-word;
      display: flex;
      .dropdown-item__content {
        align-self: center;
      }
      img {
        margin-right: 10px;
        max-width: 100%;
      }
      p {
        margin-bottom: 0;
      }
      &:active {
        text-decoration: none;
        background-color: #fff;
      }
      &:first-child {
        &:hover {
          border-radius: 5px 5px 0 0;
        }
      }
      &:last-child {
        border: 0;
        &:hover {
          border-radius: 0 0 5px 5px;
        }
      }
    }
  }
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  color: inherit;
}

.checkwrap {
  .checkwrap__cbx {
    display: none;
  }
  .checkwrap__label {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    span {
      display: inline-block;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);
      font-size: 22px;
      line-height: 35px;
      color: #555555;
    }
    .checkwrap__label_chk {
      &:first-child {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        transform: scale(1);
        vertical-align: middle;
        border: 1px solid #000;
        transition: all 0.2s ease;
        margin-right: 8px;
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          background: #fff;
          display: block;
          transform: scale(0);
          opacity: 1;
          border-radius: 50%;
        }
        svg {
          position: absolute;
          top: 4px;
          left: 3px;
          fill: none;
          stroke: #fff;
          stroke-width: 2px;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-dasharray: 16px;
          stroke-dashoffset: 16px;
          transition: all 0.3s ease;
          transition-delay: 0.1s;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}
.checkwrap
  .checkwrap__cbx:checked
  + .checkwrap__label
  .checkwrap__label_chk:first-child {
  background: #2b62b4;
  border-color: #2b62b4;
}
.checkwrap
  .checkwrap__cbx:checked
  + .checkwrap__label
  .checkwrap__label_chk:first-child
  svg {
  stroke-dashoffset: 0;
  stroke: #fff;
}
.widget {
  background: #fff;
  border: 1px solid #ebebeb;
  margin: 20px 0 0 0;
  position: relative;
  box-shadow: 0 1px 4px #c9cdd8;
  // box-shadow: 0 2px 1px rgba(0,0,0,0.09),
  //           0 4px 2px rgba(0,0,0,0.09),
  //           0 8px 4px rgba(0,0,0,0.09),
  //           0 16px 8px rgba(0,0,0,0.09),
  //           0 32px 16px rgba(0,0,0,0.09);
  border-radius: 5px;
  .widget__heading {
    padding: 20px 16px 15px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    h3 {
      color: #0a1c2a;
      font-size: 24px;
      line-height: 30px;
      margin: 0;
      font-weight: 700;
    }
  }
  &.widget--tabs-brbottom {
    .nav-tabs {
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  .t-badge {
    background: #f0f6ff;
    color: #2b62b4;
    border-radius: 2px;
    border: 1px solid #2b62b4;
    display: inline-block;
    font-size: 11px;
    padding: 2px 8px;
    line-height: 14px;
    margin-bottom: 7px;
    &.t-badge--coach {
      background: #f2fffe;
      color: #008c82;
      border-color: #00cbbc;
    }
    &.t-badge--admin {
      background: #f2f2f2;
      color: #243746;
      border-color: #546979;
    }
    &.t-badge--lg {
      background: #f3fff2;
      color: #0a9c01;
      border-color: #12cd07;
    }
  }
  .nav-tabs {
    border-bottom: none;
    padding-left: 0.75rem;
    padding-bottom: 20px;
    padding-top: 20px;
    .nav-link {
      color: rgba(10, 28, 42, 0.44);
      font-size: 16px;
      font-weight: 700;
      max-height: 38px;
      line-height: 20px;
      border: 1px solid transparent;
      margin-left: 10px;
      padding: 0.5rem;
      outline:none;
      &.active {
        color: #8d1c1d;
        background: #f3e8e8;
        border: 1px solid #8d1c1d;
        border-radius: 5px;
        outline:none;
      }
      &:hover {
        @extend .active;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .tab-pane {
    position: relative;
    .table-filter {
      background: #e5e5e5;
      position: absolute;
      top: -77px;
      width: 100%;
      min-height: 77px;
      padding: 16px 15px;
      border-radius: 5px 5px 0 0;
    }
  }
  .table {
    margin: 0;
    tr {
      td[data-label="Team"] {
        width: 280px;
        img {
          margin-bottom: 5px;
        }
      }
      td[data-label="Member Groups"] {
        width: 280px;
        img {
          margin-bottom: 5px;
        }
      }
      td {
        &:first-child {
          &.first-column--width {
            width: 400px;
          }
        }
      }
      td {
        &.column--width {
          width: auto;
        }
      }
      td {
        .media {
          min-width: 135px;
          &.media--mrgn {
            margin-bottom: 8px;
            min-width: auto;
            &:last-of-type {
                margin-bottom: 0;
            }
            .media-body {
                h5 {
                    min-width: auto !important;
                }
            }
          }
          img {
            max-height: 50px;
            max-width: 50px;
          }
        }
      }
    }
    &.table--listing {
      tr {
        td {
          &:first-child {
            width: 300px;
          }
          &:last-child {
            width: 100px;
          }
          &:nth-last-child(2) {
            width: 350px;
          }
        }
      }
    }
    &.table--listing--width {
      tr {
        th {
          width: 180px;
        }
        td {
          &:first-child {
            width: 60px;
          }
        }
      }
    }

    &.table--lastchild-smwidth {
      tr {
        td {
          &:last-child {
            width: 120px;
          }
        }
      }
    }
    // &.table-td_first-child {
    //   tr {
    //     td {
    //       &:first-child {
    //         width: auto;
    //       }
    //     }
    //   }
    // }
    tr {
      &:hover {
        background: rgba(237, 237, 237, 0.18);
      }
      td {
        &:first-child {
          width: 60px;
        }
        &.individual-chat-col{
          width:auto;
        }
        &:last-child {
          width: 200px;
        }
        .more-im {
          background: url(../../assets/images/three-dots.png) no-repeat;
          width: 25px;
          height: 9px;
          display: inline-block;
          background-size: 25px;
          &:hover {
            background: url(../../assets/images/three-dots.png) no-repeat;
            background-position: 0px -11px;
            background-size: 25px;
          }
        }
      }
    }
    .im-semirounded-outer {
      display: inline-block;
      margin-bottom: 6px;
    }
  }
  .table-bordered {
    border: none;
    tr {
      &:last-child {
        td {
          border: none;
        }
      }
    }

    td {
      border: 1px solid #ebedf4;
      border-left: none;
      border-right: none;
      vertical-align: middle;
      font-size: 15px;
      color: #31394d;
      padding: 0.7rem 0.5rem;
      .link-btn {
        h5 {
          color: #2b62b4;
          border-bottom: 1px solid transparent;
        }
        &:hover {
          h5 {
            color: #28579e;
            border-bottom: 1px solid #28579e;
          }
          text-decoration: none;
        }
      }
      h5 {
        margin-bottom: 0;
        font-size: 15px;
        color: #31394d;
        // word-break: break-word;
        word-wrap: break-word;
        min-width: 100px;
      }
      .dropdown {
        .btn-primary {
          background: transparent !important;
          border: none;
          box-shadow: none;
          padding: 0;
        }
        .dropdown-toggle {
          &:after {
            content: "";
            border: 0;
          }
        }
        &.group-actions {
          .dropdown-menu {
            .dropdown-item {
              &:last-child {
                color: #687986;
              }
            }
          }
        }
        .dropdown-menu {
          left: inherit !important;
          right: 0px;
          padding: 0;
          min-width: 10rem;
          max-width: 10rem;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
          border: none;
          transform: none !important;
          top: 30px !important;
          a {
            color: #687986;
            font-size: 15px;
            border-bottom: 1px solid #f8f8f8;
            padding: 0.5rem 1rem;
            border-left: 3px solid transparent;
            &:hover {
              border-left: 3px solid #687986;
            }
          }
          .dropdown-item {
            white-space: normal;
            &.group-actions {
              &:last-child {
                color: #687986;
              }
            }
            &:first-child {
              &:hover {
                border-radius: 5px 5px 0 0;
              }
            }
            .de-color {
              color: #dd2c00;
            }
            &:last-child:hover {
              border-radius: 0 0 5px 5px;
            }
            span {
              position: relative;
              top: 2px;
              display: inline-block;
              width: calc(100% - 29px);
              vertical-align: top;
              line-height: 18px;
            }
          }
          .dropdown-item:active {
            color: #687986;
            text-decoration: none;
            background-color: transparent;
          }
        }
      }
    }
    th {
      @extend td;
      color: #8d1c1d;
      font-size: 14px;
      background: #f6f9fc;
      &.col-sortable {
        cursor: pointer;
      }
    }
  }
}
.tab-search {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 20px;
  &.tab-search--big {
    min-width: 257px;
    right: 20px;
    &.tab-search--widget {
      position: relative;
      right: 0;
      top: 0;
      display: flex;
      justify-content: flex-end;
      padding: 15px 20px 20px 0;
      .form-inline {
        min-width: 257px;
      }
      .close-icon {
        right: 22px;
      }
    }
    .close-icon {
      position: absolute;
      right: 0;
      z-index: 0;
      &:after {
        right: 0;
      }
    }
    .form-control {
      width: 100%;
    }
  }
  .close-icon {
    z-index: 0;
  }
  &.tab-search--inner {
    position: relative;
    top: 0;
    min-width: 257px;
    .close-icon {
      position: absolute;
      right: 0;
      &:after {
        right: 0;
      }
    }
    .form-control {
      width: 100%;
    }
  }
  .form-control {
    background: url("../../assets/images/search-ic.svg") no-repeat;
    background-position: 5px 11px;
    padding-left: 30px;
    padding-right: 25px;
    border: none;
    border-bottom: 1px solid #707070;
    border-radius: 0;
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: #80bdff;
    }
  }
}
.widget{
  .table-contact{
    &.table-responsive{
      display:table;
    }
    .custom-width-column {
      width: 150px;
      &.date_created-column--detail {
        width: 185px;
      }
    }
    tr{
      td{
        &:first-child{
          width: 42px;
        }
        h5{
          min-width: 80px;
        }
      }
    }
    .badge-wrap{
      margin-right: 28px;
      .widget .table-contact .badge-wrap {
        margin-right: 15px;
    }
    }
  }
}
.custom-width-column {
  width: 130px;
  padding: 12px 0;
  &.date_created-column {
    width: 160px;
  }
  &.date_created-column--detail {
    width: 158px;
  }
}
.tab-search__box,
.close-icon {
  position: relative;
}
.close-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  visibility: visible;
}
.close-icon:after {
  display: block;
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 1;
  right: 35px;
  background: url(../../assets/images/search-cross.png) no-repeat;
  top: 0;
  bottom: 0;
  margin: auto;
  background-position: 4px;
  cursor: pointer;
}
.tab-search__box:not(:valid) ~ .close-icon {
  visibility: hidden;
}
.info-box {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
  //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11), 0 4px 4px rgba(0, 0, 0, 0.11), 0 1px 4px rgba(0, 0, 0, 0.11);
  border-radius: 3px;
  background: #fff;
  &.info-box--no-shadow {
    box-shadow: none;
    max-width: 339px;
  }
  &.info-box--space {
    margin-top: 30px;
  }
  .info-box__header {
    background: #fafafa;
    min-height: 300px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    &.info-box__header--org {
      min-height: 200px;
    }
    img {
      max-width: 100%;
    }
    .info-box__uplaod {
      position: absolute;
      width: 45px;
      height: 45px;
      right: 12px;
      top: 12px;
      border-radius: 50%;
      background: #fff;
      text-align: center;
      line-height: 42px;
      cursor: pointer;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      overflow: hidden;
      input[type="file"] {
        position: absolute;
        left: 0;
        z-index: 9;
        border: none;
        background: transparent;
        text-indent: -999px;
        width: 47px;
        cursor: pointer;
        opacity: 0;
      }
      &:hover {
        background: #fff;
      }
    }
  }
  .info-box__content {
    padding: 20px 15px;
    &.info-box__content--no-padding {
      padding: 0;
    }
    h4 {
      justify-content: space-between;
      display: flex;
      margin: 0;
      position: relative;
      span {
        font-size: 22px;
        color: #243746;
        display: inline-block;
        width: calc(100% - 45px);
        word-break: break-word;
        word-wrap: break-word;
      }
      a {
        display: inline-block;
        &.edit-icon {
          border-radius: 50%;
          width: 45px;
          height: 45px;
          transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          text-align: center;
          line-height: 39px;
          position: absolute;
          top: -7px;
          right: -8px;
          &:hover {
            background: rgba(0, 0, 0, 0.15);
          }
        }
      }
      &.isr__per-info{
        span{
          width:100%;
          &.info-box--isr-email{
              line-height: 16px;
          }
        }
      }
    }
    p {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      margin-top: 6px;
      word-break: break-word;
      word-wrap: break-word;
    }
    .info-box__content__desc {
      p {
        font-size: 16px;
        color: #858997;
      }
      .content__desc__heading {
        font-weight: 700;
        font-size: 32px;
        color: #15181a;
        text-align: right;
      }
    }
  }
}
.icon-wrap {
  width: 64px;
  height: 64px;
  background: #ececec;
  border-radius: 50%;
  text-align: center;
  line-height: 58px;
  align-self: center;
}
.m-tab-action {
  display: none;
}

/**** create team ***/

.c-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.bordered-box {
  border: 1px solid #dedede;
  border-radius: 5px;
  background: #fff;
  padding: 6px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    border: 1px solid #9a9a9a;
  }
  &.active {
    border-color: #005ff8;
  }
  .media-body {
    span {
      font-size: 15px;
      color: #31394d;
      line-height: 20px;
    }
  }
}
.brdr-right {
  border-right: 1px solid #ccc;
}
.widget__footer {
  background: #ededed;
  min-height: 90px;
  display: flex;
  flex-direction: row;
  text-align: right;
  padding: 20px;
  .btn {
    align-self: center;
  }
}
.Widget__sidebar-header {
  background: #fcfcfc;
  min-height: 75px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 0 15px;
  border-radius: 0 5px 0 0;
  display: flex;
  h3 {
    font-size: 18px;
    font-weight: 700;
    color: #162b3b;
    margin: 0;
    span {
      font-size: 18px;
      font-weight: 700;
      color: #162b3b;
    }
  }
}
.no-athlete {
  height: calc(100% - 70px);
  padding-right: 15px;
  .no-athlete__desc {
    color: #d5d5d5;
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    line-height: 30px;
    margin-top: 5px;
  }
}
.scrollarea {
  &.area {
    height: 420px;
  }
  .content {
    padding-left: 0;
  }
}
.inner-search {
  .form-control {
    background: #fff url(../../assets/images/search-grey-ic.svg) no-repeat;
    border-color: #ccc;
    border-radius: 40px;
    min-width: 245px;
    padding-left: 37px;
    background-position: 14px 12px;
  }
}
.subheading-group {
  padding: 0 16px 20px;
  p {
    margin: 0;
    color: #687986;
    font-size: 14px;
    font-style: italic;
    a {
      color: #005ff8;
      font-size: 14px;
      font-style: italic;
    }
    &.notes{
      font-style: normal;
      color: #0a1c2a;
    }
  }
  .subheading__link {
    color: #005ff8;
    font-size: 14px;
    font-style: italic;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.subheading {
  color: #0a1c2a;
  font-size: 18px;
  font-weight: 700;
  align-self: center;
  span {
    color: #687986;
    font-size: 14px;
    font-weight: 400;
    &.subheading__count {
      min-width: 27px;
      min-height: 22px;
      background: #f3f4f8;
      font-size: 12px;
      color: #687986;
      padding: 3px 6px;
      margin-left: 4px;
      max-height: 22px;
    }
  }
}
.face-wrap {
  position: relative;
  margin-bottom: 10px;
  .media {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 10px;
    font-size: 16px;
    color: #31394d;
    line-height: 20px;
    span {
      display: block;
      margin-right: 31px;
      white-space: normal;
      word-break: break-word;
    }
  }
  img {
    max-width: 100%;
  }
  .delete-action {
    background: #dd2c00;
    position: absolute;
    top: 11px;
    z-index: 9;
    width: 20px;
    height: 20px;
    line-height: 14px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 50%;
    right: 6px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 0.8;
    }
  }
}
/*** **/
.modal {
  z-index: 1090;
  .scrollarea.area {
    height: 240px;
  }
  .ml-contact-listing {
    margin-bottom: 40px;
  }
  .nav-tabs {
    background: #f7f7f7;
    border: none;
    margin: -20px 0 25px;
    padding: 15px 30px;
    border-bottom: 1px solid #ccc;
    .nav-link {
      color: rgba(10, 28, 42, 0.44);
      font-size: 16px;
      font-weight: 700;
      max-height: 38px;
      line-height: 20px;
      border: 1px solid transparent;
      margin-left: 15px;
      outline:none;
      &:first-child {
        margin-left: 0;
      }
      &.active {
        color: #8d1c1d;
        background: #f3e8e8;
        border: 1px solid #8d1c1d;
        border-radius: 5px;
        outline:none;
      }
      &:hover {
        @extend .active;
      }
    }
  }
}
.member-block {
  border: 1px solid #f0f0f0;
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 20px;
  background: #fff;
  min-width: 120px;
  .delete-action {
    cursor: pointer;
  }
}
.alert hr {
  display: block;
  width: 100%;
}
.modal {
  .m-footer {
    justify-content: space-between;
    background: #ededee;
    display: block;
    padding: 20px 25px 15px;
    margin-top: 40px;

    .m-footer__btnwrap {
      display: flex;
      justify-content: space-between;
      padding: 0;
    }
    .m-footer__ml-members {
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      .member-block {
        margin-bottom: 10px;
      }
      .row {
        flex-wrap: nowrap;
        overflow-x: auto;
        margin-bottom: 15px;
      }
    }
    .btn-secondary {
      background: transparent;
      border: 1px solid #bababa;
      box-shadow: none;
      color: #909090;
      border-radius: 3px;
      padding: 5px 15px;
      min-height: 45px;
      min-width: 125px;
      line-height: 19px;
      &:active {
        background: #d5d5d5 !important;
        color: #656565 !important;
        border-color: #d5d5d5 !important;
      }
      &:hover {
        background: #d5d5d5 !important;
        color: #656565 !important;
        border-color: #d5d5d5 !important;
      }
    }
    .btn-primary {
      background: #8d1c1d;
      border: 1px solid #8d1c1d;
      color: #fff;
      border-radius: 3px;
      padding: 5px 15px;
      min-height: 45px;
      min-width: 125px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      line-height: 32px;
      &:active {
        background: #8d1c1d!important;
        border-color: #8d1c1d!important;
        color: #fff !important;
      }
      &:hover {
        background: #7b191a !important;
        border-color: #7b191a!important;
        color: #fff !important;
      }
    }
    .btn-ternary {
      background: #8d1c1d;
      border-color: #8d1c1d;
      padding: 5px 15px;
      border-radius: 3px;
      min-height: 45px;
      min-width: 125px;
      display: flex;
      &:hover {
        background: #7b191a !important;
        border-color: #7b191a !important;
      }
      &:disabled {
        background-color: #8d1c1d !important;
        border-color: #8d1c1d !important;
        cursor: not-allowed;
        opacity: 0.7;
      }
      .btn__icon {
        align-self: center;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        min-height: 33px;
        &.spinner-border {
          min-height: initial;
          align-self: center;
        }
      }
    }
    .btn-blue {
      background: #8d1c1d;
      border-color: #8d1c1d;
      &:hover {
        background-color: #7b191a !important;
        border-color: #7b191a !important;
      }
    }
    .btn-ternary--width-auto {
      min-width: inherit;
    }
  }
}
.modal-header {
  padding: 20px 20px 30px 30px;
  display: block;
  .modal-subtitle {
    margin: 0;
    font-size: 24px;
    color: #687986;
    font-weight: bold;
  }
  .close {
    float: right;
    font-size: 2rem;
    font-weight: 500;
    color: #9f9f9f;
    position: absolute;
    top: 10px;
    right: 20px;
  }
}
.modal-body {
  padding: 20px 30px;
  min-height: 250px;
  &.flex-row {
    .row {
      margin: 0;
    }
  }
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 28px;
  font-weight: bold;
  color: #0a1c2a;
}
.ml-left {
  background: #e7e7e7;
  border-radius: 5px;
  min-width: 126px;
  min-height: 126px;
  padding: 10px;
  display: inline-block;
  text-align: center;
  line-height: 105px;
  max-height: 126px;
  margin-right: 25px;
}
.form-control {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
textarea {
  &.form-control {
    min-height: 100px;
    background: #fafafa;
    border: none;
    margin: 0;
    padding-top: 24px;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.ml-right {
  width: calc(100% - 260px);
  .form-control {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  textarea {
    &.form-control {
      min-height: 100px;
      background: #fafafa;
      border: none;
      margin: 0;
      padding-top: 24px;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .select-wrap {
    position: relative;
    background: #fafafa;
    padding-left: 15px;
    select {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      position: relative;
      background: transparent;
      border: none;
      border-bottom: none;
      border-radius: 0;
      min-height: 55px;
      width: 100%;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    &:before {
      background: url(../../assets/images/sel-arrow.svg) no-repeat;
      content: "";
      width: 47px;
      height: 40px;
      position: absolute;
      right: 0;
      background-position: 16px 17px;
      border-left: 1px solid #ccc;
      top: 8px;
    }
  }
}

/** team listing **/
.badge-wrap {
  display: inline-block;
  width: 75px;
  margin-right: 40px;
  text-align: center;
}
.row--space {
  margin-top: 40px;
  .dropdown {
    .btn-primary {
      margin-right: 0;
    }
  }
}
h1 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
  color: #0a1c2a;
  margin: 0;
}
.coach-grp {
  img {
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
  }
  .im-rounded-outer {
    margin: 0 10px 5px 0;
    display: inline-block;
  }
}
.vh-height {
  height: calc(100% - 260px);
}
.members-outer {
  max-width: 62px;
  max-height: 62px;
  .face-wrap {
    img {
      max-width: 100%;
    }
    .delete-action {
      top: 2px;
      right: -11px;
    }
  }
}
.carousel-outer {
  border: 1px solid #d5d5d5;
  margin: 60px 0 30px 0;
  .nav {
    border-bottom: 1px solid #ededed;
    .nav-item {
      a {
        color: rgba(10, 28, 42, 0.44);
        font-weight: bold;
        font-size: 18px;
        border-bottom: 2px solid transparent;
        padding: 10px 20px 12px;
        &.active {
          color: #0a1c2a;
          border-color: #1b1c1d;
        }
      }
    }
  }
  .tab-content {
    padding: 10px 15px;
  }
}

.alice-carousel__next-btn {
  padding: 0 !important;
  box-sizing: border-box;
  position: absolute;
  top: -50px;
  right: 0;
  border-radius: 50%;
  width: 28px !important;
  height: 28px;
  border: 1px solid #ccc;
  background: url("../../assets/images/next-icon.png") no-repeat 10px 7px;
  &:hover {
    background-position: 10px -15px;
  }
  p {
    margin: 0;
    span {
      visibility: hidden;
    }
  }
}
.alice-carousel__prev-btn {
  text-align: right;
  @extend .alice-carousel__next-btn;
  right: 36px;
  background: url("../../assets/images/prev-icon.png") no-repeat 9px 7px;
  &:hover {
    background-position: 9px -17px;
  }
  p {
    margin: 0;
    span {
      visibility: hidden;
    }
  }
}
.badge-count {
  background: rgba(138, 247, 232, 0.4);
  padding: 0 10px;
  border-radius: 30px;
  min-width: 30px;
  min-height: 20px;
  display: inline-block;
  text-align: center;
  border: 1px solid #3fdbcf;
  max-height: 20px;
  font-size: 10px;
  color: #008c82;
  line-height: 17px;
  margin-left: 6px;
  vertical-align: middle;
}
.rgt-link {
  a {
    padding: 6px 8px;
    display: inline-block;
    &:hover {
      background: rgba(25, 118, 210, 0.08);
      border-radius: 5px;
      text-decoration: none;
    }
    i {
      font-style: normal;
    }
  }
}
.announcement-group-listing{
  margin: 0 0 0 8%;
  padding: 0;
  width: 80%;
}
.announcement-group-listing li{
  text-align: left;
  text-transform: capitalize;
}
.confirmbox {
  h1 {
    font-size: 32px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  p {
    color: rgba(10, 28, 42, 0.6);
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 35px;
  }
}
.circle-outer {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border: 4px solid #00cbbc;
  line-height: 91px;
  border-radius: 50%;
}
.tab-content {
  .fade {
    opacity: 1 !important;
  }
}
.modal {
  &.fade {
    opacity: 1;
  }
}
.success-msg {
  margin-top: 40px;
  img {
    margin: 0 auto 30px;
  }
  p {
    color: #0A1C2A;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin: 0 auto;
    width: 74%;
  }
  h1 {
    color: #0a9c01;
  }
}
.dropdown-item {
  .im-rounded-outer {
    width: 35px;
    height: 35px;
    overflow: hidden;
    text-align: center;
    background: #ccc;
    border-radius: 50%;
    margin-right: 0.5rem;
    line-height: 30px;
    display: inline-block;
    vertical-align: top;
    img {
      max-width: 100%;
      max-height: 100%;
      margin: 0 !important;
    }
  }
}
@supports (-ms-ime-align: auto) {
  .widget .table-bordered td h5 {
    word-break: break-word;
    word-wrap: break-word;
  }
}
.lg-view {
  margin-top: 40px;
  h5 {
    font-size: 18px;
    color: #0a1c2a;
    font-weight: 700;
  }
}
.widget--lg-view {
  margin-bottom: 60px;
}
.white-color {
  color: #fff;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
.block-pos__inner {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
}
.desk-hide {
  display: none;
}
.mb--small-res {
  display: none;
}
.widget--mrgn{
  margin-top: 40px;;
}
.info-box--mrgn{
  margin-top:40px;
}
@media only screen and (min-width: 1950px) {
  .widget {
    .table {
      tr {
        td[data-label="Team"] {
          width: 25%;
        }
        td[data-label="Member Groups"] {
          width: 25%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1650px) {
  .block-pos__inner {
    .mr-4 {
      margin-right: 8px !important;
    }
    .ml-4 {
      margin-left: 8px !important;
    }
    .btn {
      font-size: 14px;
    }
    .btn-secondary {
      padding: 5px 10px;
    }
    .btn-medium {
      padding: 5px 10px;
    }
  }
}
.widget{
  .table{
    &.table--super-admin-width{
      tr{
        td{
          &:nth-last-child(2){
            width:190px !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1450px) {
  .block-pos {
    &.block-pos--position {
      .mr-4 {
        margin-right: 8px !important;
      }
    }
  }
  .widget {
    margin-right: 0;
    .nav-tabs {
      .nav-link {
        font-size: 15px;
        margin-left: 8px;
        padding: 0.5rem;
      }
    }
  }
  .info-box {
    .info-box__header {
      min-height: 200px;
      img {
        max-width: 90%;
      }
    }
  }
  .btn__position {
    right: 16px;
  }
  .widget {
    .table {
      &.table--listing {
        tr {
          td {
            &:first-child {
              width: 220px;
            }
            &:nth-last-child(2) {
              width: 280px;
            }
          }
        }
      }
      &.table--listing--history {
        tr {
          td {
            &:first-child {
              width: 434px;
            }
            &:nth-last-child(2) {
              width: 182px;
            }
          }
        }
      }
      tr {
        td[data-label="Team"] {
          width: 230px;
        }
        td[data-label="Member Groups"] {
          width: 230px;
        }
        td {
          &.column--width {
            width: auto;
          }
        }
      }
    }
  }
  .info-box {
    .info-box__content {
      h4 {
        span {
          font-size: 22px;
        }
      }
      .btn-cancel {
        min-width: 100px;
      }
    }
  }
}
@media only screen and (max-width: 1390px) and (min-width: 1200px) {
  .info-box__content {
    .btn-cancel {
      min-width: 68px !important;
      padding: 5px 8px;
      font-size: 15px;
    }
    .btn-ternary {
      padding: 5px;
    }
  }
  .btn-icon {
    &.btn-icon--delete {
      min-width: 32px;
      background-position: 5px 4px;
    }
  }
  .btn-icon.btn-icon--delete:hover,
  .btn-icon.btn-icon--delete:active,
  .btn-icon.btn-icon--delete.btn-primary:not(:disabled):not(.disabled):active {
    background-position: 5px -38px;
  }
}
@media only screen and (max-width: 1300px) {
  .widget {
    margin-right: 0;
    .nav-tabs {
      .nav-link {
        font-size: 14px;
        margin-left: 8px;
        padding: 0.4rem 0.2rem;
      }
    }
  }
  .block-pos__inner {
    .mr-4 {
      margin-right: 8px !important;
    }
    .ml-4 {
      margin-left: 8px !important;
    }
    .btn {
      font-size: 13px;
    }
    .btn-secondary {
      padding: 5px 9px;
    }
    .btn-medium {
      padding: 5px 8px;
    }
    .btn-icon {
      min-width: 30px;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .md--full-width {
    width: 100% !important;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-lg-9 {
    &.order-md-2 {
      -ms-flex-order: 2;
      order: 2;
    }
  }
  .col-lg-3 {
    &.order-md-3 {
      -ms-flex-order: 3;
      order: 3;
    }
  }
  .widget {
    .table {
      tr {
        td[data-label="Team"] {
          width: 160px;
        }
        td[data-label="Member Groups"] {
          width: 160px;
        }
        td {
          &.column--width {
            width: auto;
          }
        }
        td {
          &:first-child {
            &.first-column--width {
              width: 200px;
            }
          }
        }
      }
      &.table--listing {
        tr {
          td {
            &:first-child {
              width: 200px;
            }
            &:nth-last-child(2) {
              width: 250px;
            }
          }
        }
      }
      &.table--listing--history {
        tr {
          td {
            &:first-child {
              width: 240px;
            }
            &:nth-last-child(2) {
              width: 134px;
            }
          }
        }
      }
    }
    .table-contact{
      &.table-responsive{
        display:block;
      }
    }
  }
  .dropdown-outer {
    .dropdown-toggle {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  .rgt-content {
    .breadcrumb {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}
@media only screen and (max-width: 1140px) and (min-width:1025px){
  .widget{
    .table-contact{
      tr{
        td{
          padding: 0.7rem 0.3rem;
          font-size: 14px;
          h5{
            min-width: 80px;
          }
          &:nth-child(3){
            width: 150px;
          }
          &:nth-last-child(2){
            width: 150px;
          }
        }

      }
      .badge-wrap{
        margin-right: 15px;
      }
    }
  }
}
@media only screen and (max-width: 800px) and (min-width:768px){
  .widget{
    .table-contact{
      tr{
        td{
          padding: 0.7rem 0.3rem;
          font-size: 13px;
          h5{
            min-width: 80px;
          }
          &:nth-child(3){
            width: 150px;
          }
          &:nth-last-child(2){
            width: 150px;
          }
        }

      }
      .badge-wrap{
        margin-right: 15px;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .topbar {
    z-index: 99999999;
    min-height: 60px;
    max-height: 60px;
    .navbar-toggler {
      display: none;
    }
    &.navbar-expand-lg {
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: start;
      justify-content: flex-start;
      position: fixed;
      width: 100%;
      top: 0;
      .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
      }
    }
    .dropdown {
      .dropdown-menu {
        top: 52px;
      }
    }
    .sidebar-menu {
      .navbar-expand-lg {
        .navbar-collapse {
          &:not(.show) {
            display: block;
          }
        }
      }
    }
    .hamburger-ic {
      display: inline-block;
      cursor: pointer;
      flex-direction: row;
      margin-right: 18px;
    }
    .m-search {
      display: inline-block;
    }
    .header-search {
      display: none;
    }
  }
  .rgt-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 55px;
    width: 100%;
  }
  .org-logo {
    display: inline-block;
  }
  .modal-backdrop {
    z-index: 99999999;
  }
  .modal {
    z-index: 99999999;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .topbar {
    max-height: 65px;
    min-height: 65px;
    .badge-wrap {
      margin-bottom: 0;
    }
    .t-badge {
      &.t-badge--lg {
        margin-bottom: 0;
      }
    }
  }
  .sidebar-wrap{
    margin-top: 65px !important;
  }
}
@media only screen and (max-width: 991px) {
  .notification {
    background: url("../../assets/images/mob-notify.svg") no-repeat;
    height: 30px;
    background-position: 6px 9px;
  }
  .modal-dialog {
    max-width: 700px;
  }
  .ml-right {
    width: calc(100% - 160px);
  }
  .widget {
    .nav-tabs {
      .nav-link {
        margin-left: 8px;
        padding: 0.5rem 0.3rem;
      }
    }
  }
  .scrollarea {
    .content {
      padding-left: 0;
      padding-top: 0;
    }
  }
  .widget {
    .table {
      &.table--listing {
        tr {
          td {
            &:first-child {
              width: 200px;
            }
            &:nth-last-child(2) {
              width: 270px;
            }
          }
        }
      }
      &.table--listing--history {
        tr {
          td {
            &:first-child {
              width: 224px;
            }
            &:nth-last-child(2) {
              width: 135px;
            }
          }
        }
      }
    }
  }
  .row--space {
    margin-top: 20px;
  }
  .rgt-content {
    padding-top: 68px;
  }
}
@media only screen and (max-width: 991px) and(min-width:768px) {
  html,
  body,
  #root,
  .base {
    height: 100%;
  }
  .ipd-height100 {
    height: 100%;
  }
  .vh-height {
    height: calc(100% - 235px);
  }
  .topbar {
    .dropdown {
      &.prf_user {
        .media {
          .media-body {
            h5 {
              max-width: 350px;
            }
          }
        }
      }
    }
  }
  .modal {
    .mdl-table {
      tr {
        border-bottom: 1px solid #ccc;
        &:last-child {
          border: none;
        }
        td {
          padding-left: 0;
          padding-right: 0;
          &:first-child {
            width: 100%;
          }
          display: block;
          border: none;
          &.text-right {
            text-align: left !important;
          }
          &:last-child{
            text-align: left;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .topbar {
    .dropdown {
      &.prf_user {
        .media {
          .media-body {
            h5 {
              max-width: 300px;
            }
          }
        }
      }
    }
  }
  .brdr-right {
    border-right: none;
  }
  .vh-height {
    height: auto;
  }
  .lg-view {
    margin-top: 20px;
    h5 {
      font-size: 16px;
    }
  }
  .widget--lg-view {
    margin-bottom: 50px;
  }
  .block-pos__inner {
    .btn-secondary {
      padding: 5px 9px;
      min-height: 40px;
      margin-bottom: 20px;
    }
    .btn-medium {
      margin-bottom: 20px;
    }
  }
  .block-pos--position {
    &.justify-content-between {
      .btn-icon {
        align-self: baseline;
        margin-right: 20px !important;
      }
    }
  }
  .widget {
    .tab-pane {
      .table-filter {
        top: -91px;
        min-height: 70px;
        padding: 15px;
        position: static;
        margin-bottom: 15px;
        .btn-secondary {
          padding: 2px 15px;
          min-height: 40px;
          font-size: 16px;
        }
        .delete__icon {
          height: 36px;
          width: 36px;
          line-height: 36px;
          img {
            width: 22px;
          }
        }
      }
    }
    .tab-search {
      right: unset;
      left: 15px;
      min-width: 207px;
      &.tab-search--inner {
        left: 0;
      }
      &.tab-search--widget {
        left: 0;
      }
    }
    table {
      &.table-bordered {
        td {
          border: none;
        }
      }
      &.tab-responsive {
        thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
        tbody {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
        tr {
          border-bottom: 1px solid #dedede;
          display: block;
          margin-bottom: 0;
          &:last-child {
            border: none;
          }
          &.mb--norecord {
            td {
              padding: 0.7rem 1rem 0.7rem 1em;
            }
          }
        }
        td {
          border-bottom: none;
          display: flex;
          padding: 0.7rem 1rem 0.7rem 148px;
          flex-wrap: wrap;
          &.td-content-space {
            flex-direction: column;
            &:before {
              padding-top: 0;
            }
          }
          &:before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: none;
            position: absolute;
            left: 15px;
            font-size: 14px;
            color: #8d1c1d;
          }

          &:last-child {
            border-bottom: 0;
            width: 245px;
            .dropdown {
              align-self: flex-end;
              width: 36px;
              text-align: right;
              .dropdown-menu {
                right: unset;
                left: 0 !important;
              }
            }
          }
          .media {
            &.media--mrgn {
              min-width: 100% !important;
            }
          }
        }
        td[data-label="Team"],
        td[data-label="Member Groups"] {
          width: auto;
          img {
            margin-bottom: 10px;
          }
        }
      }
    }
    .nav-tabs {
      margin-left: 0;
      margin-top: 75px;
      background: #f6f9fc;
      padding: 15px 15px 5px;
      .nav-link {
        margin: 0 10px 10px 0;
        padding: 0.5rem;
        font-size: 16px;
      }
    }
    &.widget--tabs-brbottom {
      .nav-tabs {
        border-bottom: none;
        margin-bottom: 30px;
        margin-right: -15px;
        margin-top: 0;
      }
    }
  }
  .sm-order {
    -ms-flex-order: 4;
    order: 4;
  }
  .btn__position {
    right: 0;
    position: static !important;
    top: unset;
    min-height: 40px;
    font-size: 16px;
    margin: 15px 0 0 0;
  }
  .Widget__sidebar-header {
    margin-left: 0;
  }
  .no-athlete {
    padding: 20px;
  }
  .face-wrap {
    max-width: 80%;
    margin: 0 auto 30px;
  }
  .m-footer {
    .m-footer__ml-members {
      .face-wrap {
        margin: 0 25px 20px 0;
        max-width: 65px;
      }
    }
  }
  .modal-body {
    padding: 20px;
    .nav-tabs {
      margin: -20px -20px 25px;
      padding: 15px 20px;
    }
  }
  .modal-header {
    padding: 15px 20px 20px 20px;
    .modal-title {
      font-size: 20px;
    }
    .modal-subtitle {
      font-size: 17px;
    }
  }
  .m-footer {
    padding: 15px 20px;
  }
  h1 {
    font-size: 20px;
    line-height: 24px;
  }
  .btn-medium {
    padding: 5px 10px;
    min-height: 40px;
  }
  .btn {
    font-size: 16px;
    line-height: 20px;
  }
  .dropdown-outer {
    .dropdown-outer__content {
      font-size: 20px;
      line-height: 30px;
    }
    .btn-primary {
      font-size: 22px;
      margin: 0 0 0 0;
    }
  }
  .widget {
    .table {
      &.table--listing {
        tr {
          td {
            &:first-child {
              width: 100%;
            }
            &:nth-last-child(2) {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .modal {
    .m-footer {
      .btn-secondary {
        min-width: 90px;
      }
      .btn-primary {
        min-width: 90px;
      }
      .m-footer__ml-members {
        .face-wrap {
          margin-right: 20px;
          max-width: 55px;
          .delete-action {
            height: 20px;
            width: 20px;
            line-height: 14px;
          }
        }
      }
    }
    .ml-right {
      textarea {
        &.form-control {
          min-height: 100px;
        }
      }
      .form-control {
        min-height: 45px;
        font-size: 16px;
      }
      .select-wrap {
        padding-left: 0;
        &:before {
          height: 31px;
          background-position: 16px 13px;
          top: 5px;
        }
      }
    }
  }
  .btn-rounded {
    text-align: left;
  }
  .badge-wrap {
    margin-right: 0;
    text-align: left;
  }
  .success-msg {
    p {
      font-size: 20px;
      line-height: 25px;
      width: 90%;
    }
  }
  .modal {
    .mdl-table-outer {
      margin: 40px -20px 0;
    }
    .mdl-topbar {
      span {
        font-size: 15px;
      }
    }
  }
  aside {
    .y-scroll {
      overflow-x: hidden;
      position: relative;

      .sidebar-menu {
        height: calc(100vh - 280px);
        min-height: 200px;
        overflow-y: auto;
      }
      footer {
        position: static;
        flex-direction: column-reverse;
        padding: 15px 0 0;
        width: 184px;
      }
    }
  }
  .modal {
    .mdl-table {
      tr {
        border-bottom: 1px solid #ccc;
        &:last-child {
          border: none;
        }
        td {
          padding-left: 0;
          padding-right: 0;
          &:first-child {
            width: 100%;
          }
          display: block;
          border: none;
          &.text-right {
            text-align: left !important;
          }
        }
      }
    }
  }
  .topbar {
    .dropdown {
      &.prf_user {
        .media {
          padding-right: 25px;
          align-items: center;
          .media-body {
            .badge-wrap {
              display: none;
            }
          }
        }
      }
      .desk-hide {
        display: block;
      }
      .prf-heading {
        background: #e8e8e8;
        text-transform: uppercase;
        font-size: 12px;
        padding: 3px 15px;
        margin-bottom: 3px !important;
        margin-top: 2px;
      }
    }
  }
  .topbar {
    .dropdown {
      &.prf_user {
        .dropdown-menu {
          .dropdown-item {
            padding: 6px 35px 6px 40px;
          }
        }
      }
      .dropdown-menu {
        max-height: 210px;
        overflow-y: auto;
      }
    }
    .t-badge {
      margin-bottom: 0;
    }
  }
  .dropdown-divider {
    margin: 5px 0;
  }
  .custom-width-column {
    width:100%;
  }
}
@media only screen and (max-width: 600px) {
  footer {
    display: flex;
    flex-direction: column;
    //img {
    //  -ms-flex-order: 2;
    //  order: 2;
    //}
    //ul {
    //  -ms-flex-order: 1;
    //  order: 1;
    //  margin: 0 auto 20px;
    //}
  }
  .mob--fl-direction {
    flex-direction: column;
  }
  .inner-search {
    width: 300px;
    margin: 20px auto 0;
  }
  .m-footer {
    .btn-secondary {
      padding: 5px 10px;
      min-height: 45px;
      min-width: 90px;
    }
    .btn-primary {
      padding: 5px 10px;
      min-height: 45px;
      min-width: 90px;
    }
  }
  .confirmbox {
    h1 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    p {
      font-size: 17px;
      line-height: 24px;
    }
  }
  .org-logo {
    img {
      max-width: 200px;
    }
  }
  .block-pos__inner {
    width: 100%;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    .btn-medium {
      margin-bottom: 0;
    }
  }
  .widget {
    .tab-search {
      .form-control {
        margin-right: 0;
        display: inline-block;
        width: auto;
        vertical-align: middle;
        &.mr-sm-3 {
          margin-right: 1rem !important;
        }
      }
      &.tab-search--widget {
        justify-content: flex-start;
        min-width: 234px;
        max-width: 234px;
        left: 15px;
        .close-icon {
          right: 9px;
        }
        .form-inline {
          min-width: auto;
        }
      }
    }
    .table-bordered {
      td {
        h5 {
          min-width: auto;
        }
        .media {
          min-width: auto !important;
          flex-wrap: wrap;
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .row--space {
    .text-right {
      text-align: left !important;
      margin-top: 15px;
    }
  }
  .mb-hide {
    display: none;
  }
  .mb--small-res {
    display: block;
    padding: 10px 15px 3px;
    font-size: 17px;
    line-height: 22px;
  }
  .block-pos__inner {
    width: 100%;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    .btn-medium {
      margin-bottom: 0;
    }
  }
  .widget {
    .tab-search {
      .form-control {
        margin-right: 0;
        display: inline-block;
        width: auto;
        vertical-align: middle;
        &.mr-sm-3 {
          margin-right: 1rem !important;
        }
      }
      &.tab-search--widget {
        justify-content: flex-start;
        min-width: 234px;
        max-width: 234px;
        left: 15px;
        .close-icon {
          right: 9px;
        }
        .form-inline {
          min-width: auto;
        }
      }
    }
    .table-bordered {
      td {
        h5 {
          min-width: auto;
        }
        .media {
          min-width: auto !important;
        }
      }
    }
  }
  .custom-width-column {
    width:100%;
  }
}
@media only screen and (max-width: 550px) and (min-width: 500px) {
  .btn-cancel {
    min-width: 100px;
  }
  .ml-left {
    display: none;
  }
  .ml-right {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .ml-right {
    width: 100%;
  }
  .ml-left {
    display: none;
  }
  .m-footer {
    .m-footer__ml-members {
      .face-wrap {
        max-width: 50px;
        margin: 0 20px 10px 0;
        .delete-action {
          height: 20px;
          width: 20px;
          top: 0px;
          line-height: 14px;
          right: -6px;
        }
      }
    }
  }
  .modal {
    .nav-tabs {
      .nav-link {
        font-size: 16px;
        padding: 0.5rem 0.7rem;
        margin-bottom: 5px;
        margin-top: 5px;
      }
    }
  }
  .row--space {
    margin-top: 15px;
  }
  .modal {
    .m-footer {
      .m-footer__btnwrap {
        &.m-footer__btnwrap--lg-btn {
          display: flex;
          justify-content: flex-start;
          padding: 0;
          flex-direction: column-reverse;
          margin-left: 0;
          .btn {
            min-width: 130px;
            max-width: 95%;
            margin: 0 auto 10px;
          }
        }
      }
    }
  }
  .org-logo {
    img {
      max-width: 160px;
    }
  }
}
@media only screen and (max-width: 400px) {
  .widget {
    table {
      &.tab-responsive {
        td {
          border-bottom: none;
          display: flex;
          padding: 0.7rem 1rem 0.7rem 140px;
          &:before {
            font-size: 13px;
          }
        }
      }
    }
  }
  .inner-search {
    width: 230px;
    .form-control {
      min-width: 230px;
    }
  }
  .face-wrap {
    max-width: 100%;
  }
  .tab-search {
    &.tab-search--inner {
      min-width: 100%;
    }
  }
  .org-logo {
    img {
      max-width: 100px;
    }
  }
}
@media (min-height: 200px) and (max-height: 460px) {
  aside {
    .y-scroll {

      overflow-x: hidden;
      position: relative;
      .sidebar-menu {
        height: calc(100vh - 280px);
        min-height: 160px;
        overflow-y: auto;
      }
      footer {
        position: static;
        flex-direction: column-reverse;
        padding: 15px 0 0;
        width: 184px;
      }
    }
  }
}
