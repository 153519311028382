.detail-icon{
    position: relative;
    top: -4px;
}
.im-rounded{
    border-radius:50%;
    width:50px;
    height: 50px;
}
.im-rounded-outer{
    width: 50px;
    height: 50px;
    overflow: hidden;
    text-align: center;
    background: #ccc;
    border-radius: 50%;
    margin-right: .5rem;
    line-height: 44px;
    img{
        max-width: 100%;
        max-height: 100%;
        margin: 0 !important;
    }
}
.im-semirounded-outer{
    width: 50px;
    height: 50px;
    overflow: hidden;
    text-align: center;
    background: #ccc;
    border-radius: 5px;
    margin-right: .5rem;
    line-height: 44px;
    img{
        max-width: 100%;
        max-height: 100%;
        margin: 0 !important;
    }
}

    

.more-actions{
    display: inline-block;
}
.widget .table tr td.student-badge-width{
    width:130px !important;
}
@media only screen and (min-width:1451px){
    .more-actions{
        text-align: right;
        margin-right: 20px;
    }
}