.layoutouter {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    &.layoutouter--mrgn-right{
        margin-right:55px;
    }
    &.layoutouter--mrgn-btm{
        margin-bottom: 40px;
        margin-top: 24px;
    }
    .layoutouter__col{
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}
.pos-relative{
    position: relative;
}
.tab-search {
    &.tab-search--pos-static{
        position: static;
    }
}
.short-text {
    word-break: break-word;
}
textarea{
    &.form-control{
        background: #FAFAFA;
        border:none;
        border-bottom:1px solid #eaeae8;
        min-height: 55px;
        border-radius: 0;
        font-size: 18px;
        line-height: 25px;
        color:#333;
        margin-bottom: 20px;
        resize:none;
        &:focus{
            outline:none;
            box-shadow: none;
            background: #FAFAFA;
            color:#333;
            border-bottom:1px solid #eaeae8;
        }
    }
    &.textarea--min-height{
        min-height: 200px;
    }
    &:focus{
        outline:none;
        box-shadow: none;
    }
}
.face-wrap-outer{    
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 20px -10px 0;
}
.face-wrap--width{
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 75px;
    padding: 0 10px;
    margin: 0 auto 15px;
    .delete-action{
        top:0;
        right:9px;
    }
}

.member-listing {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: inside decimal;
    width:100%;
    li {
        margin-bottom: 10px;
        flex: 0 0 33%;
        max-width: 33%;
        padding: 0 15px;

        em {
            font-style: normal;
            margin-left: 7px;
        }
    }
}
@media only screen and (max-width:1450px){
    .layoutouter {
        &.layoutouter--mrgn-right{
            margin-right:0;
        }
    }
}
@media only screen and (max-width:1199px){
    .layoutouter {
        .layoutouter__col {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
    .face-wrap--width {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 0 10px;
    }
}
@media only screen and (max-width:991px){
    .face-wrap--width {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 0 10px;
    }
    .member-listing {
        li {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
@media only screen and (max-width:767px){
    .layoutouter {
        .layoutouter__col {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .face-wrap--width {
        flex: 0 0 33%;
        max-width: 75px;
        padding: 0 10px;
    }

}
@media only screen and (max-width:620px) {
    .member-listing {
        li {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
