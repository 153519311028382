.widget{
    table{
        &.table--invoice{
            td{
                &:first-child{
                    width:350px;
                }
            }
        }
    }
}
.header-row{
    padding:15px 0 15px 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    .header-row__block{
        max-width: 300px;
        padding: 0 15px;
        &:last-child{
            padding-right:0;
        }
        label{
            font-weight: 800;
            text-transform: uppercase;
            display: flex;
            max-width: 100%;
            flex: 0 0 100%;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 3px;
            font-size: 15px;
        }
        .header-row__desc{
            display: flex;
            max-width: 100%;
            flex: 0 0 100%;
            flex-direction: row;
            flex-wrap: wrap;            
            font-size: 17px;
        }
    }
}
.heading--alignment{
    align-items: center;
    display: flex;
}
.filter-date{
    label{
        color: #687986;
        font-size: 16px;
        margin: 0 10px 0px 0;
        align-self: center;
    }
    .react-datepicker__input-container{
        input{
            border: 1px solid #a5a5a5;
            border-radius: 5px;
            box-shadow: none;
            background-color: #FAFAFA !important;
            padding: 5px 33px 5px 10px;
            background-position: 97% 5px !important;
            max-width: 200px;
        }
    } 
    .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
        width:3.4rem;
    }
}
.table{
    &.table--invoice{
        tr {
            &.mb--norecord{
                td{
                    &:last-child{
                        width:100%;
                    }
                }
            }
            td{
                &:last-child{
                    width:100px;
                }
            }
        }
        
    }
}
@media only screen and (max-width:576px){
    .header-row {
        padding: 20px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        .header-row__block{
            max-width: 300px;
            padding: 0 15px;
            &:first-child{
                padding-left:0;
            }
        }
    }
}